import { useEffect, useMemo, useState } from 'react';

import { getGoogleStaticMapUrl } from '../utils';
import { SectionLoader } from './Loaders';

const MAP_SIZE = 200;

const getStreetViewURLs = ({ coordinates }) => {
  const latLonStr = [...coordinates].reverse().join(',');
  const googleMapsToken = window.processEnv?.BuyersAwareReactAppGoogleMapsToken;
  if (!googleMapsToken) {
    console.error('Google Maps API token is missing!');
    return {
      metaDataEndpoint: '',
      streetViewImgEndpoint: '',
    };
  }

  const metaDataEndpoint = `https://maps.googleapis.com/maps/api/streetview/metadata?location=${latLonStr}&source=outdoor&key=${googleMapsToken}`;
  const streetViewImgEndpoint = `https://maps.googleapis.com/maps/api/streetview?size=${MAP_SIZE}x${MAP_SIZE}&location=${latLonStr}&source=outdoor&return_error_code=true&key=${googleMapsToken}`;

  return {
    metaDataEndpoint,
    streetViewImgEndpoint,
  };
};

const ResultsStreetview = ({ coordinates, includeMap = true }) => {
  const { metaDataEndpoint, streetViewImgEndpoint } = getStreetViewURLs({ coordinates });
  const [streetViewStatus, setStreetViewStatus] = useState('LOADING');

  useEffect(() => {
    const fetchStreetviewData = async () => {
      setStreetViewStatus('LOADING');
      const metaDataReq = await fetch(metaDataEndpoint);
      const metaDataResp = await metaDataReq.json();
      if (metaDataResp.status !== 'OK') {
        setStreetViewStatus('ERROR');
        return;
      }
      setStreetViewStatus('READY');
    };

    fetchStreetviewData();
  }, [metaDataEndpoint]);

  const mapURl = useMemo(() => {
    return getGoogleStaticMapUrl({
      centerLocationCoordinate: coordinates,
      centerMarker: true,
      mapHeight: MAP_SIZE,
      mapWidth: MAP_SIZE,
      zoom: 18,
    });
  }, [coordinates]);

  return (
    <div className="flex flex-col gap-2">
      <div className="h-[200px] w-[200px] bg-gray-200">
        {(streetViewStatus === 'READY' || streetViewStatus === 'OK') && (
          <img
            src={streetViewImgEndpoint}
            alt="street view of location"
            onError={() => setStreetViewStatus('ERROR')}
            onLoad={() => {
              setStreetViewStatus('OK');
            }}
          />
        )}
        {streetViewStatus === 'LOADING' && <SectionLoader dotColor={'slate'} />}
        {streetViewStatus === 'ERROR' && (
          <div className="flex h-full w-full items-center justify-center px-2 align-middle text-sm">
            <div className="text-center text-rain">
              Sorry, we are unable to load an image of this location.
            </div>
          </div>
        )}
      </div>
      {includeMap && (
        <div className="h-[200px] w-[200px] bg-gray-200">
          <img src={mapURl} alt="map centered on location" />
        </div>
      )}
    </div>
  );
};

export default ResultsStreetview;
