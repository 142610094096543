import team from '../assets/team.jpg';

export const About = () => {
  return (
    <>
      <div className="flex justify-center bg-snow px-4 py-3 md:px-8 md:py-6 lg:px-12 lg:py-9">
        <div className="w-full max-w-screen-lg">
          <h1 className="font-condensed text-3xl text-rain lg:text-4xl xl:text-5xl">About Us</h1>
          <p>
            In 2017, Hurricane Harvey reminded us all that floods are one of the most common and
            costliest natural disasters, and they can pose a significant risk to human life and
            property. Identifying your flood risk is an important first step to determining if any
            actions are necessary to reduce your flood risk, assess insurance needs, and plan for
            potential impacts. Buyers Aware is the result of a partnership between researchers and
            faculty members at Texas A&M University, the Texas Division of Emergency Management
            (TDEM), and FEMA to make this information more widely available by creating a one-stop
            shop analytical tool to assess the flood and wildfire risk to individual residential
            structures in Hurricane Harvey-impacted counties in Texas.
          </p>
          <h2 className="mt-6 font-condensed text-2xl text-rain">Why Buyers Aware?</h2>
          <p>
            Our goal is to provide accurate and timely flood and wildfire risk information to home
            buyers, renters, and residents. Our property-specific analysis includes data from
            federal regulatory maps as well as advanced statistical models in an easily
            interpretable and transparent format. We have listened to Texas residents throughout the
            design process to ensure Buyers Aware is simple to use and easy to understand.
          </p>
          <p>
            Buyers Aware is funded by the Texas Division of Emergency Management through funds
            allocated by FEMA to fund flood mitigation planning and communication. This generous
            support enables us to provide Buyers Aware as a free tool, available widely to the
            public, grounded in advanced research.
          </p>
          <h2 className="mt-6 font-condensed text-2xl text-rain">About IDRT</h2>
          <p>
            The Institute for a Disaster Resilient Texas (IDRT) is an applied research institute
            located within Texas A&M University and one of the largest flood risk research
            institutes in the nation. Our vision is a Texas that is well-prepared for disasters and
            more resilient when they do occur, as a direct result of decision-making tools, data,
            and research that IDRT and our partners provide. Through a collaborative process and
            multi-disciplinary approach, the Institute’s mission is to deliver critical research on
            disaster risk reduction through data analytics and decision-making tools and generate
            evidence-based solutions that help Texas communities become more resilient.
          </p>
          <h2 className="my-6 font-condensed text-2xl text-rain">Our Team</h2>
          <img src={team} alt="array of small photos of team members" />
        </div>
      </div>
    </>
  );
};
