import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { toast } from 'react-toastify';

const API_ROOT = process.env.REACT_APP_API_ROOT || '/api';

// Then use API_ROOT as defined earlier

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: API_ROOT }),
  endpoints: build => ({
    getRiskForAddress: build.query({
      query: ({ address, coordinates }) => ({
        url: '/property/risks',
        method: 'POST',
        body: {
          address,
          coordinates,
        },
      }),
    }),
    getRiskForId: build.query({
      query: ({ id }) => ({
        url: '/property/risks-by-id',
        method: 'POST',
        body: {
          id,
        },
      }),
    }),
    getNeighborhoodProfile: build.query({
      query: ({ bbox }) => ({
        url: '/property/neighborhood-profile',
        method: 'POST',
        body: {
          bbox,
        },
      }),
    }),
  }),
});

export const downloadReport = async ({ address, coordinates }) => {
  try {
    const reportFetch = await fetch(`${API_ROOT}/property/report`, {
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        address,
        coordinates,
      }),
      method: 'POST',
    });
    const stream = await reportFetch.blob();
    const blob = new Blob([stream], { type: 'application/pdf' });
    const dataUrl = window.URL.createObjectURL(blob);
    const downloadLink = document.createElement('a');
    downloadLink.href = dataUrl;
    downloadLink.download = 'report';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  } catch (error) {
    toast.error('Oops! We are unable to create a report for this address, please try again later.');
    console.error(error);
  }
};

// These export hooks are automatically defined by createApi, they
// can be imported in functional components to fetch data, see fetch
// status and get the data from the redux store.
export const {
  useGetNeighborhoodProfileQuery,
  useGetRiskForAddressQuery,
  useGetRiskForIdQuery,
  useLazyGetNeighborhoodProfileQuery,
} = api;
