import floodIcon from '../assets/icons/flood.svg';
import fireIcon from '../assets/icons/fire.svg';
import sandbagsImage from '../assets/sandbags.jpeg';
import { FIRE, FLOOD, MITIGATION_COPY } from '../constants/general';
import { PhotoSectionHeader } from './general/PhotoSectionHeader';
import { TabbedSection } from './general/TabbedSection';
import { Fragment, useState } from 'react';
import { capitalize } from '../utils';

const MoreInfoForRisk = {
  [FLOOD]: (
    <>
      For more information on flood mitigation options, customized to your home type, please visit{' '}
      <a className="cursor-pointer underline" href="https://www.reducefloodrisk.org">
        https://www.reducefloodrisk.org
      </a>
      .
    </>
  ),
  [FIRE]: (
    <>
      For more information on mitigating wildfire risk, see{' '}
      <a
        className="cursor-pointer underline"
        href="https://www.nfpa.org/Public-Education/Fire-causes-and-risks/Wildfire/Preparing-homes-for-wildfire"
      >
        https://www.nfpa.org/Public-Education/Fire-causes-and-risks/Wildfire/Preparing-homes-for-wildfire
      </a>
      .
    </>
  ),
};

const MitigationForRisk = ({ icon, risk }) => {
  return (
    <div className="px-8 pb-8 pt-6">
      <h2 className="mt-6 font-condensed text-2xl text-rain">
        {capitalize(risk)} Mitigation Strategies and Preparedness Tactics
      </h2>
      <div className="pb-8">
        {MITIGATION_COPY[risk].map(copy => (
          <Fragment key={copy.header}>
            <h3 className="pt-6 font-condensed">{copy.header}</h3>
            <p className="mt-2">{copy.body}</p>
            <img className="m-auto mt-8 w-4" alt={`${risk} icon`} src={icon} />
          </Fragment>
        ))}
      </div>
      <div className="md:text-lg">{MoreInfoForRisk[risk]}</div>
    </div>
  );
};

export const Mitigation = () => {
  const risks = [
    {
      icon: floodIcon,
      label: 'Flood Mitigation Strategies',
      riskType: FLOOD,
    },
    {
      icon: fireIcon,
      label: 'Wildfire Mitigation Strategies',
      riskType: FIRE,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <PhotoSectionHeader
        header="Protect your property"
        content="Floods and wildfires are costly events for residents. However, there are several steps that
            can be taken to reduce your risk &mdash; these steps are referred to as mitigation.
            Review the details below to learn how to protect your property."
        imgSrc={sandbagsImage}
      />
      <div className="flex justify-center bg-ice px-8 py-6 lg:px-12 lg:py-9">
        <TabbedSection
          activeTabIndex={activeTab}
          setActiveTabIndex={setActiveTab}
          tabs={risks}
          wrapperClass="flex justify-center bg-ice px-0 py-8"
        >
          <MitigationForRisk risk={risks[activeTab].riskType} icon={risks[activeTab].icon} />
        </TabbedSection>
      </div>
    </>
  );
};
