import BFEGraphic from '../assets/base-flood-elevation-bfe.svg';

const FFElevation = () => {
  return (
    <div>
      <p className="text-xs text-rain sm:text-sm">
        Buyers Aware assesses the risk to the property, not necessarily the structure(s). One
        important characteristic that cannot be easily accounted for is what is referred to as First
        Floor Elevation (FFE). The FFE refers to the height of the floor above a reference point,
        often measured in relation to the base flood elevation (BFE). It indicates how high the
        lowest habitable floor of a building is situated above the potential floodwaters.
      </p>
      <div className="flex justify-center">
        <img
          className="w-full max-w-2xl"
          alt="Graphic of residence below, at, and above base flood elevation"
          src={BFEGraphic}
        />
      </div>
      <p className="text-xs text-rain sm:text-sm">
        For example, estimated flood depth due to a hurricane on the coast may be 10 feet. A
        structure is hopefully elevated on piers at heights of 10 feet or more to be above the
        estimated flood depth, or BFE, reducing the structure’s flood risk. Alternatively, older
        structures may be slab foundations with little to no extra elevation. Building codes and
        regulations require structures in flood-prone areas to have a specified first floor
        elevation above the base flood elevation, often with an additional height (referred to as
        freeboard). Compliance with these standards is crucial for obtaining permits and ensuring
        the resilience of communities to flooding.
      </p>
    </div>
  );
};

export default FFElevation;
