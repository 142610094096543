import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/outline';

const CollapsableBody = ({ children, duration, isOpen }) => {
  const contentRef = useRef();
  const [height, setHeight] = useState(isOpen ? 'auto' : '0');

  // As isOpen is toggled, this effect will trigger the height of the surrounding ref to
  // fit the content. By setting transition css properties on height, it creates the
  // illusion of a collapsing div.
  useEffect(() => {
    const contentRefRect = contentRef.current.getBoundingClientRect();
    setHeight(isOpen ? `${contentRefRect.height}px` : '0');
  }, [isOpen]);

  return (
    <div
      style={{
        height,
        overflow: height === 'auto' ? 'visible' : 'hidden',
        transition: `height ${duration}ms ease-in-out`,
      }}
    >
      <div ref={contentRef}>{children}</div>
    </div>
  );
};

const CollapsableInfo = ({ className = '', sections }) => {
  const [openSections, setOpenSections] = useState({});

  return (
    <div className={clsx('border-b border-l border-r border-fossil', className)}>
      {sections.map(({ header, body }, index) => {
        const isOpen = openSections[index];
        const toggleSection = () => {
          if (isOpen) {
            const { [index]: _, ...newOpenSections } = openSections;
            setOpenSections(newOpenSections);
          } else {
            setOpenSections({ ...openSections, [index]: true });
          }
        };
        return (
          <div key={header} onClick={toggleSection}>
            <button
              aria-expanded={isOpen}
              aria-controls={`panel-${header.split(' ').join('-')}`}
              className={
                'flex w-full items-center justify-between border-t border-fossil px-6 py-4'
              }
            >
              <div className="text-sm font-bold text-rain md:text-base">{header}</div>
              <ChevronDownIcon
                className={clsx(
                  'w-6 text-slate transition-transform duration-300 ease-in-out',
                  !isOpen && '-rotate-180'
                )}
              />
            </button>
            <div
              className="cursor-pointer"
              id={`panel-${header.split(' ').join('-')}`}
              onClick={toggleSection}
            >
              <CollapsableBody duration={150} isOpen={isOpen}>
                <p className="mx-6 my-0 border-t border-fossil pb-6 pt-3">{body}</p>
              </CollapsableBody>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CollapsableInfo;
