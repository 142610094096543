import {
  FIRE,
  FLOOD,
  FLOOD_RISK_LEVEL_DESCRIPTIONS,
  RISK_DESCRIPTIONS_REPORT,
} from '../constants/general';

export const Methodology = () => {
  const h2Class = 'mt-6 font-condensed text-2xl text-rain';
  const h3Class = 'mt-6 font-condensed text-xl text-rain';
  const ulClass = 'mx-2 sm:mx-6';
  const liHeaderClass = 'mb-2 mt-4 font-bold italic text-rain';
  return (
    <>
      <div className="flex justify-center bg-snow px-4 py-3 md:px-8 md:py-6 lg:px-12 lg:py-9">
        <div className="w-full max-w-screen-lg">
          <h1 className="font-condensed text-3xl text-rain lg:text-4xl xl:text-5xl">Methodology</h1>
          <h2 className={h2Class}>How do we calculate risk?</h2>
          <p>
            Buyers Aware uses a combination of open-source data coupled with site-specific,
            peer-reviewed analytics. Flood risk is determined through a statistical analysis of
            traditional factors that influence flood risk combined with the locations of historic
            flood damage. The result of this analysis is a robust measure of flood risk that
            reflects existing flood risk products but also detects areas of risk that are frequently
            underestimated. In addition, we provide an estimate of flood risk – whether a property
            is in or out of a FEMA Special Flood Hazard Area – as determined by FEMA regulatory maps
            (i.e., floodplains).
          </p>

          <h3 className={h3Class}>
            When calculating a property’s flood risk, we analyze the following variables:
          </h3>
          <ul className={ulClass}>
            {RISK_DESCRIPTIONS_REPORT[FLOOD].map(risk => (
              <li key={risk.label}>
                <div className={liHeaderClass}>{risk.label}</div>
                <p>{risk.description}</p>
              </li>
            ))}
          </ul>

          <h3 className={h3Class}>
            When calculating a property’s wildfire risk, we analyze the following variables:
          </h3>
          <ul className={ulClass}>
            {RISK_DESCRIPTIONS_REPORT[FIRE].map(risk => (
              <li key={risk.label}>
                <div className={liHeaderClass}>{risk.label}</div>
                <p>{risk.description}</p>
              </li>
            ))}
          </ul>
          <h2 className={h2Class}>What do we mean by low, moderate, and high risk?</h2>
          <p>
            For flood risk, Buyers Aware’s risk rankings represent a property’s annual likelihood of
            flooding 1 inch or greater. The ranking levels can be interpreted as:
          </p>
          <ul className={ulClass}>
            {FLOOD_RISK_LEVEL_DESCRIPTIONS.map(risk => (
              <li key={risk.label}>
                <div className={liHeaderClass}>{risk.label}</div>
                <p>{risk.description}</p>
              </li>
            ))}
          </ul>

          <p>
            For wildfire risk, Buyers Aware’s risk rankings represent a property’s annual likelihood
            of experiencing a wildfire.
          </p>

          <h2 className={h2Class}>
            Why are Buyer Aware’s results different from FEMA’s Special Flood Hazard Area?
          </h2>
          <p>
            Since the 1990s, over 50% of flood loss in Texas has occurred in areas outside of SFHAs.
            That reality meant thousands of residents who suffered flood damage were not aware they
            were at risk of flood damage and did not have flood insurance – and it continued to
            happen after multiple disasters. This is the driving force behind our team of
            researchers developing this platform to build upon the latest research and why our
            federal and state partners are funding it.
          </p>
          <p>
            Based on our model, some properties are considered high or moderate risk on Buyers Aware
            but do not fall within a FEMA Special Flood Hazard Area, meaning the property is not
            within the so called 100-year floodplain and owners are not required to purchase flood
            insurance for this property.
          </p>
          <h2 className={h2Class}>What are FEMA Flood Zones?</h2>
          <p>
            Flood zones are geographic areas that the FEMA has defined according to varying levels
            of flood risk; as such, different flood zones reflect differing severity and types of
            flooding. These flood zones are used by FEMA to develop Flood Insurance Rate Maps (FIRM)
            that serve important regulatory functions, such as determining in which areas property
            owners are mandated to purchase flood insurance. For more information about FEMA Flood
            Zones, see{' '}
            <a
              className="cursor-pointer break-words underline"
              href="https://www.fema.gov/glossary/flood-zones"
            >
              https://www.fema.gov/glossary/flood-zones
            </a>
            .
          </p>
          <h2 className={h2Class}>What are Special Flood Hazard Areas (SFHAs)?</h2>
          <p>
            Special Flood Hazard Areas are areas designated by FEMA as having special flood, mudflow
            or flood-related erosion hazards. Specifically, FEMA defines SFHAs as areas “that will
            be inundated by the flood event having a 1-percent chance of being equaled or exceeded
            in any given year” – this is often referred to as the “100-year floodplain.” Importantly
            for residents, SFHAs are areas where the mandatory purchase of flood insurance applies,
            and certain building regulations are enforced.
          </p>
          <p>
            The flood zones considered SFHAs are labeled as Zones A, Zone AO, Zone AH, Zones A1-A30,
            Zone AE, Zone A99, Zone AR, Zone AR/AE, Zone AR/AO, Zone AR/A1-A30, Zone AR/A, Zone V,
            Zone VE, and Zones V1-V30. For more information about SFHAs, see{' '}
            <a
              className="cursor-pointer break-words underline"
              href="https://www.fema.gov/glossary/flood-zones"
            >
              https://www.fema.gov/glossary/flood-zones
            </a>
            .
          </p>
          <br />
          <p>
            Detailed technical information regarding the analytical approach used by Buyers Aware
            can be found in our public, peer-reviewed research available at{' '}
            <a
              className="cursor-pointer break-words underline"
              href="https://nhess.copernicus.org/articles/21/807/2021"
            >
              https://nhess.copernicus.org/articles/21/807/2021/
            </a>
            .
          </p>
        </div>
      </div>
    </>
  );
};
