import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import logo from '../assets/baw-logo-horizontal-color.svg';
import { useGetRiskForAddressQuery } from '../services/api';
import ResultsOverview from './ResultsOverview';
import { FIRE, FLOOD, RISK_DESCRIPTIONS_REPORT } from '../constants/general';
import { capitalize } from '../utils';
import FemaZoneWarning from './FemaZoneWarning';
import RiskIcon from './general/RiskIcon';
import ResultsAssessment from './ResultsAssessment';
import { AreaContext } from './ResultsSections';
import FFElevation from './FFElevation';

const Break = () => <div className="my-8 h-[1px] bg-rain" />;

const SectionHeader = ({ text }) => (
  <h2 className="my-4 font-condensed text-2xl text-rain">{text}</h2>
);

const SectionSubHeader = ({ text }) => (
  <h3 className="my-2 font-condensed text-xl text-rain">{text}</h3>
);

const OverallScoreString = ({ data, riskType }) => {
  if (riskType === FIRE) {
    return '';
  }
  return (
    <div>
      This property has a{' '}
      <span className="font-bold">{`${riskType} risk score of ${data.risk}.`}</span> The Risk Score
      is calculated by factoring the individual hazards below.
    </div>
  );
};

const HazardTable = ({ data, rows }) => {
  return (
    <>
      <div
        className="mx-4 my-8 border border-solid border-slate"
        style={{ pageBreakInside: 'avoid' }}
      >
        <div className="flex justify-between border-b border-slate px-2 py-4 font-bold text-rain">
          <div>Hazard Type</div>
          <div>Value</div>
        </div>
        {rows.map(row => (
          <div
            className="flex justify-between border-b border-l border-r border-gray-300 px-2 py-4"
            key={row.label}
          >
            <div>{row.label}</div>
            <div>
              {data[row.accessor]
                ? `${row.format ? row.format(data[row.accessor]) : data[row.accessor]}${
                    row.unit && ` ${row.unit}`
                  }`
                : 'Value not available for this location'}
            </div>
          </div>
        ))}
      </div>
      <h3 className="my-2 font-condensed text-lg text-rain">Hazard Risk Description</h3>
      {rows.map(row => (
        <div className="my-4" key={row.label}>
          <span className="font-bold text-rain">{row.label}:</span> {row.description}
        </div>
      ))}
    </>
  );
};

const ReportForRisk = ({ address, coordinates, data, riskType }) => {
  return (
    <>
      <SectionHeader text={`${capitalize(riskType)} Risk Report`} />
      <SectionSubHeader text="Overview" />
      <div className="mt-8 flex justify-center gap-16">
        <div className="w-[200px]" style={{ pageBreakInside: 'avoid' }}>
          <RiskIcon risk={data.risk} riskType={riskType} />
        </div>
        {riskType === FLOOD && (
          <div className="mt-6 w-[200px]">
            <FemaZoneWarning
              isFemaSpecialHazardArea={data.isFemaSpecialHazardArea}
              reportPage={true}
            />
          </div>
        )}
      </div>
      <div className="w-full text-rain">
        <ResultsAssessment
          address={address}
          coordinates={coordinates}
          data={data}
          riskType={riskType}
          reportPage
        />
      </div>
      <div className="[&>*:first-child]:!px-0">
        <AreaContext
          address={address}
          coordinates={coordinates}
          data={data}
          riskType={riskType}
          reportPage
        />
      </div>
      <SectionSubHeader
        text={riskType === FLOOD ? 'Our Risk Calculation' : 'Wildfire Risk Details'}
      />
      <OverallScoreString riskType={riskType} data={data} />
      <HazardTable data={data} rows={RISK_DESCRIPTIONS_REPORT[riskType]} />
      <Break />
    </>
  );
};

const Report = () => {
  const [searchParams] = useSearchParams();
  const address = searchParams.get('address');
  const coordinates = searchParams.get('coordinates').split(',');

  const {
    data: addressRiskData,
    error,
    isLoading,
  } = useGetRiskForAddressQuery({
    address,
    coordinates: coordinates,
  });

  // Assume secrets are available globally
  const secrets = window.processEnv;
  console.log('Global secrets:', JSON.stringify(secrets));

  // Verify secrets exist before rendering
  if (error || isLoading || !secrets) {
    return null;
  }

  const risks = [
    {
      address: address,
      coordinates: coordinates,
      data: addressRiskData[FLOOD],
      riskType: FLOOD,
    },
    {
      address: address,
      coordinates: coordinates,
      data: addressRiskData[FIRE],
      riskType: FIRE,
    },
  ];

  return (
    <div>
      <div className={`flex w-full pb-2`}>
        <img className="mb-2 h-[40px]" src={logo} alt="Buyers Be-Where logo" />
      </div>
      <div className="bg-ice">
        <ResultsOverview addressRiskData={addressRiskData} reportPage />
      </div>
      {risks.map(risk => (
        <ReportForRisk {...risk} />
      ))}
      <FFElevation />
      <Break />
      <SectionHeader text="About Buyers Aware" />
      <p className="mb-4">
        Floods are one of the most common and costliest natural disasters, and they can pose a
        significant risk to human life and property. Identifying your flood risk is an important
        first step to determining if any actions are necessary to reduce your flood risk, assess
        insurance needs, and plan for potential impacts. Buyers Aware is a one-stop shop to assess
        the flood and wildfire risk to individual residential structures. Developed by researchers
        at the Institute for a Disaster Resilient Texas at Texas A&M University, Buyers Aware is a
        free public information platform funded by the Texas Division of Emergency Management
        through funds allocated by FEMA for hazard mitigation in Texas. Our goal is to provide
        accurate and timely flood and wildfire risk information to Texas home buyers, renters, and
        residents. Buyers Aware's property-specific analysis builds upon data from regulatory risk
        maps and incorporates advanced statistical models to provide a state-of-the-art risk
        analysis in an easily interpretable and transparent format.
      </p>
      <SectionHeader text="About IDRT" />
      <p className="mb-4">
        The Institute for a Disaster Resilient Texas (IDRT) is an applied research institute located
        within Texas A&M University and one of the largest flood risk research institutes in the
        nation. Our vision is a Texas that is well-prepared for disasters and more resilient when
        they do occur, as a direct result of decision-making tools, data, and research that IDRT and
        our partners provide. Through a collaborative process and multi-disciplinary approach, the
        Institute’s mission is to deliver critical research on disaster risk reduction through data
        analytics and decision-making tools and generate evidence-based solutions that help Texas
        communities become more resilient.
      </p>
      <p>{`Learn more about Buyers Aware and IDRT on our About Us page: ${secrets.BuyersAwareReactAppSiteURL}about`}</p>
    </div>
  );
};

export default Report;
