import flood from '../../assets/icons/flood.svg';
import fire from '../../assets/icons/fire.svg';
import lowRisk from '../../assets/icons/risk/low.svg';
import mediumLowRisk from '../../assets/icons/risk/medium_low.svg';
import mediumRisk from '../../assets/icons/risk/medium.svg';
import mediumHighRisk from '../../assets/icons/risk/medium_high.svg';
import highRisk from '../../assets/icons/risk/high.svg';
import { riskToText } from '../../utils';

/**
 * @param {{
 *   risk: number
 *   riskType: "flood" | "wildfire",
 * }} props
 */
const RiskIcon = ({ risk, riskType }) => {
  let iconSrc = '';
  switch (risk) {
    case 1:
      iconSrc = lowRisk;
      break;
    case 2:
      iconSrc = mediumLowRisk;
      break;
    case 3:
      iconSrc = mediumRisk;
      break;
    case 4:
      iconSrc = mediumHighRisk;
      break;
    case 5:
      iconSrc = highRisk;
      break;
    default:
      iconSrc = lowRisk;
      break;
  }

  return (
    <div className="relative flex w-full flex-col pt-[10%]">
      <div
        className="absolute flex w-1/4 rounded-full border border-gray-200 bg-snow p-1.5"
        style={{ transform: 'translateY(-40%)' }}
      >
        <img alt={`${riskType} icon`} className="w-full" src={riskType === 'fire' ? fire : flood} />
      </div>
      <img
        alt={`risk score gauge showing ${riskToText(risk)} risk`}
        className="z-10"
        src={iconSrc}
      />
      <div className="text-center font-condensed text-rain">
        <span className="text-lg font-bold">{riskToText(risk)} </span>
        {`${riskType} risk`}
      </div>
    </div>
  );
};

export default RiskIcon;
