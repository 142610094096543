import { formatDecimal2SigFig } from '../utils';

// Session expiration time (for localStorage)
export const SESSION_EXP_STORAGE_KEY = 'jafebp_session_expiration_time';

export const FIRE = 'wildfire';
export const FLOOD = 'flood';

export const RISK_VARIABLE_COPY = {
  [FIRE]: [
    {
      header: 'Proximity to areas of high wildfire risk',
      main: 'Areas closer to dense vegetation or dry brush are more likely to experience wildfires.',
    },
  ],
  [FLOOD]: [
    {
      header: 'Land elevation',
      subHeader: 'Height of the terrain at a property',
      main: 'Areas that are lower in elevation or flat tend to act like natural paths for water to flow, which can cause them to flood or collect water during times of rain.',
    },
    {
      header: 'Distance to coastline',
      main: 'Areas closer to the coast are more likely to experience flooding from high tides or storm surge.',
    },
    {
      header: 'Distance to stream',
      main: 'Areas close to a stream are more prone to flooding from the stream overflowing its banks.',
    },
    {
      header: 'Imperviousness',
      subHeader: 'Percent of landcover made up of urban impervious surface',
      main: 'Areas with more impervious surface experience less natural infiltration of water into the ground. This leads to more runoff that can overwhelm drainage systems and cause water to overflow into areas that are normally dry.',
    },
    {
      header: 'Soil Characteristics',
      subHeader: 'The rate at which water moves through soil',
      main: 'Areas with smoother surfaces and less pervious soil types can be more prone to flooding. During heavy rainfalls the amount of water can be too much for the ground to absorb or move too quickly overwhelming the drainage system.',
    },
    {
      header: 'Height above nearest drainage (HAND)',
      subHeader: 'Height of property relative to the nearest stream, lake, or coastline',
      main: 'A property that has low elevation relative to the nearest drainage path is more likely to experience flooding.',
    },
  ],
};

export const MITIGATION_COPY = {
  [FIRE]: [
    {
      header: 'Regular maintenance',
      body: 'Regularly maintaining the property by clearing gutters, removing dead vegetation, and trimming trees is generally the least costly mitigation option. Homeowners can often do this work themselves, or hire a professional at relatively low cost.',
    },
    {
      header: 'Creating defensible space',
      body: 'Creating a buffer zone around the home by clearing brush and other vegetation can be somewhat costly, as it may require renting equipment or hiring a professional to clear the area. However, the cost can vary widely depending on the size of the property and the extent of the work required.',
    },
    {
      header: 'Being aware of local conditions',
      body: 'Staying informed about local weather conditions and wildfire risk levels is a critical part of preparedness.',
    },
    {
      header: 'Preparing for evacuation',
      body: 'Also critical to preparedness is having an evacuation plan in place and keeping a "go bag" with important documents and supplies.',
    },
    {
      header: 'Using fire-resistant materials',
      body: 'Constructing or retrofitting the home with fire-resistant materials such as metal roofing, fire-rated windows, and non-combustible siding can be a costly option, as it may require significant renovations or construction. However, the cost can vary widely depending on the size and type of the property.',
    },
    {
      header: 'Installing fire suppression systems',
      body: 'Installing a sprinkler system around the home and/or a rooftop sprinkler system is generally the most costly mitigation option. It requires professional installation and can be expensive, especially for larger properties.',
    },
  ],
  [FLOOD]: [
    {
      header: 'Improving drainage',
      body: 'By improving the drainage around the property, homeowners can reduce the risk of flood damage. This may involve installing drainage ditches, improving the grading of the property, or installing drainage systems This option is often the least costly as it may only require simple improvements to grading, vegetation, and/or installation of drainage systems.',
    },
    {
      header: 'Retrofitting',
      body: 'There are several retrofitting options that can help reduce flood damage. This may include adding flood vents to allow water to flow through the home, sealing entry points, and installing backflow preventers to prevent water from backing up into the home.',
    },
    {
      header: 'Flood barriers',
      body: 'Flood barriers can be installed around the perimeter of the property to prevent floodwaters from entering the home. These barriers can be permanent or temporary, depending on the needs of the homeowner. The cost of flood barriers can vary widely depending on the size of the property and the type of barrier being used. For example, sandbags may be relatively inexpensive, while permanent flood walls can be quite costly.',
    },
    {
      header: 'Elevating the property',
      body: 'This involves raising the elevation of the home to make it less susceptible to floodwaters. It may involve adding a foundation or retrofitting an existing foundation. Elevating a home can be the most costly mitigation option, as it often involves significant construction work and permits. The cost of this option can range from tens of thousands to hundreds of thousands of dollars depending on the home’s size and elevation requirements.',
    },
  ],
};

export const RISK_DESCRIPTIONS_REPORT = {
  [FLOOD]: [
    {
      accessor: 'elevation',
      label: 'Elevation',
      description:
        'Land elevation at property. Areas that are lower in elevation or flat tend to act like natural paths for water to flow, which can cause them to flood or collect water during times of rain. A lower elevation value increases a property’s calculated flood risk.',
      unit: 'ft',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'distanceCoast',
      label: 'Distance to Coast',
      description:
        'Distance from property to coastline. Areas closer to the coast are more likely to experience flooding from high tides or storm surge. A smaller Distance to Coast value increases a property’s calculated flood risk.',
      unit: 'miles',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'distanceStream',
      label: 'Distance to Stream',
      description:
        'Distance from property to nearest stream. Areas close to a stream are more prone to flooding from the stream overflowing its banks. A smaller Distance to Stream value increases a property’s calculated flood risk.',
      unit: 'miles',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'hand',
      label: 'Height Above Nearest Drainage',
      description:
        'Vertical distance between property and the nearest drainage point (stream, lake, or coastline). A property that has low elevation relative to the nearest drainage path is more likely to experience flooding. A smaller Height Above Nearest Drainage value increases a property’s calculated flood risk.',
      unit: 'ft',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'roughness',
      label: 'Roughness',
      description:
        'Average physical roughness of landcover around the property. A smaller Roughness value increases a property’s calculated flood risk. Roughness is a measure of flowing waters resistance as a result of different types of vegetation and land cover.',
      unit: '',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'ksat',
      label: 'Saturated Hydraulic Conductivity',
      description:
        'Rate at which water moves through soil at the property. A lower Saturated Hydraulic Conductivity rate increases a property’s calculated flood risk. The slower the water can move through the soil, the less that water can move through the larger hydrologic system.',
      unit: 'in/hr',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'imperviousness',
      label: 'Imperviousness',
      description:
        'Percent of landcover made up of urban impervious. Areas with more impervious surface experience less natural infiltration of water into the ground. This leads to more runoff that can overwhelm drainage systems and cause water to overflow into areas that are normally dry. A higher Imperviousness value increases a property’s calculated flood risk.',
      unit: '%',
      format: formatDecimal2SigFig,
    },
  ],
  [FIRE]: [
    {
      accessor: 'burnProbability',
      label: 'Burn Probability',
      description:
        'The likelihood of a wildfire burning at any given location based on landscape characteristics, weather conditions, and fire management practices. A higher probability indicates a greater chance that a wildfire will occur.',
      unit: '',
      format: formatDecimal2SigFig,
    },
    {
      accessor: 'flep',
      label: '4-foot flame length probability',
      description:
        'Probability of flames that greater than 4 feet in length. A flame length of 4 feet is generally considered the limit of manual fire control. This measure represents the likelihood that intensity of a wildfire would exceed the ability of hand crews to contain the fire. A higher probability indicates the potential for moderate to high wildfire intensity.',
      unit: '',
      format: formatDecimal2SigFig,
    },
  ],
};

export const FLOOD_RISK_LEVEL_DESCRIPTIONS = [
  {
    label: 'Low Risk',
    description:
      'Analysis of the property’s flood risk variables indicates it has a less-than-0.1% annual chance of flooding.',
  },
  {
    label: 'Moderate-Low Risk',
    description:
      'Analysis of the property’s flood risk variables indicates it has between a 0.1-0.2% annual chance of flooding.',
  },
  {
    label: 'Moderate Risk',
    description:
      'Analysis of the property’s flood risk variables indicates it has a between-0.2%-and-0.4% annual. ',
  },
  {
    label: ' Moderate-High Risk',
    description:
      'Analysis of the property’s flood risk variables indicates it has a between-0.4 and 1% annual chance of flooding.',
  },
  {
    label: 'High Risk',
    description:
      'Analysis of the property’s flood risk variables indicates it has a 1%-or-greater annual chance of flooding.',
  },
];
