import { SAGE } from '../constants/colors';

const TILE_SIZE = 256; // Default Google maps tile size

/**
 * This file has a number of helper functions to allow us to plot on a static google map.
 * Fair warning, the math for this is a little tricky and involved.
 * Not only do locationCoordinates [lon, lat] have to be converted to the web mercator
 * projection, but then we have to account for the map zoom level and its offset.
 *
 * First a bit of terminology:
 *   A locationCoordinate is an array of [lon, lat] that defines a geographic location
 *   A projectedLocationCoordinate is that coordinate projected to the web mercator projection
 *      and scaled to a single google maps tile. The origin for this coordinate is [0,0]
 *   A worldCoordinate is a pixel location of a coordinate on the zoomed google map (basically
 *      the previous value scaled by a map's zoom factor).  It's origin is also [0,0]
 *   A pixelCoordinate is the worldCoordinate's pixelCoordinate on the screen given a cropped google map
 *
 * For more reading, see
 *   https://wiki.openstreetmap.org/wiki/Mercator#JavaScript
 *   https://stackoverflow.com/questions/12688092/google-maps-static-api-get-sw-and-ne-by-center-coordinate
 *   https://developers.google.com/maps/documentation/javascript/examples/map-coordinates?csw=1
 */

/**
 * Converts a WGS84 coordinate to the web mercator projection, scaled to a single tile
 * https://developers.google.com/maps/documentation/javascript/examples/map-coordinates?csw=1
 * The mapping between latitude, longitude and pixels is defined by the web
 * mercator projection.
 * Returns a projectedLocationCoordinate, that is the pixel coordinate of the location on a single
 * 256 x 256 google tile.
 * @param {[number, number]} locationCoordinate [lon, lat] in terms of WGS84 aka SRID:4326
 */
export const projectWebMercator = locationCoordinate => {
  const [lon, lat] = locationCoordinate;

  let siny = Math.sin((lat * Math.PI) / 180);

  // Truncating to 0.9999 effectively limits latitude to 89.189. This is
  // about a third of a tile past the edge of the world tile.
  siny = Math.min(Math.max(siny, -0.9999), 0.9999);

  return [
    TILE_SIZE * (0.5 + lon / 360),
    TILE_SIZE * (0.5 - Math.log((1 + siny) / (1 - siny)) / (4 * Math.PI)),
  ];
};

/**
 * The inverse of the web mercator projection. Given a projectedLocationCoordinate it converts
 * it to a WGS84 lon, lat
 * @param {[number, number]} worldCoordinate [x, y]
 */
export const inverseProjectWebMercator = worldCoordinate => {
  const lon = (worldCoordinate[0] / TILE_SIZE) * 360 - 180;
  const latRadians = (worldCoordinate[1] - TILE_SIZE / 2) / -(TILE_SIZE / (2 * Math.PI));
  const lat = Math.atan(Math.sinh(latRadians)) * (180.0 / Math.PI);
  return [lon, lat];
};

/**
 * Given the pixelCoordinate of a a point on a static google map image, return its lon,lat in terms of WGS84
 * @param {Object} mapDetails
 * @param {[number, number]} mapDetails.centerLocationCoordinate The center of the map as a WGS84 lon,lat
 * @param {number} mapDetails.mapHeight The height of the rendered google map
 * @param {number} mapDetails.mapWidth The width of the rendered google map
 * @param {[number, number]} mapDetails.pixelCoordinate The [x, y] coordinate on the rendered google map
 * @param {number} mapDetails.zoom The zoom level of the google map
 */
export const pointToLonLat = ({
  centerLocationCoordinate,
  mapHeight,
  mapWidth,
  pixelCoordinate,
  zoom,
}) => {
  // First find the projected coordinate of the center location, aka where it would be on the single tile
  const worldCoordinateCenter = projectWebMercator(centerLocationCoordinate);

  // Every zoom value increase doubles the size of the map, this is our scale factor
  const scale = Math.pow(2, zoom);

  // We figure out how far the pixelCoordinate is from the center of the map and divide it by the scale...
  const pointOffsetX = (pixelCoordinate[0] - mapWidth / 2) / scale;
  const pointOffsetY = (pixelCoordinate[1] - mapHeight / 2) / scale;

  // ...and this gives us enough information to know where the point would be with a web mercator projection
  // on a single tile
  const pixelCoordinateWorld = [
    worldCoordinateCenter[0] + pointOffsetX,
    worldCoordinateCenter[1] + pointOffsetY,
  ];

  // Feeding that value back into the inverse function gives us the lon,lat we need
  return inverseProjectWebMercator(pixelCoordinateWorld);
};

/**
 * Given a WGS84 lon,lat return the pixelCoordinate of that value on a static google map image
 * @param {Object} mapDetails
 * @param {[number, number]} mapDetails.centerLocationCoordinate The center of the map as a WGS84 lon,lat
 * @param {[number, number]} mapDetails.locationCoordinate The WGS84 lon,lat we're looking to conver to a pixel value
 * @param {number} mapDetails.mapHeight The height of the rendered google map
 * @param {number} mapDetails.mapWidth The width of the rendered google map
 * @param {number} mapDetails.zoom The zoom level of the google map
 */
export const lonLatToPoint = ({
  centerLocationCoordinate,
  locationCoordinate,
  mapHeight,
  mapWidth,
  zoom,
}) => {
  // Find the world coordinate of the center location
  const worldCoordinateCenter = projectWebMercator(centerLocationCoordinate);

  // Find the world coordinate of the location we're trying to convert
  const worldCoordinatePoint = projectWebMercator(locationCoordinate);

  // Every zoom value increase doubles the size of the map, this is our scale factor
  const scale = Math.pow(2, zoom);

  // By figuring out the difference in world coordinate space between the center coordinate
  // and the desired coordinate and then multiplying by the scale, we can figure out how
  // far we need to plot the pixel from the center...
  const offsetX = (worldCoordinatePoint[0] - worldCoordinateCenter[0]) * scale;
  const offsetY = (worldCoordinatePoint[1] - worldCoordinateCenter[1]) * scale;

  // ...so we add those values to the centerX and centerY pixel coordinates to get our
  // final pixel coordinate
  const x = mapWidth / 2 + offsetX;
  const y = mapHeight / 2 + offsetY;
  return [x, y];
};

/**
 * Get the bounds of a static google map image in terms of WGS84 lon,lat
 * @param {Object} mapDetails
 * @param {[number, number]} mapDetails.centerLocationCoordinate The center of the map as a WGS84 lon,lat
 * @param {number} mapDetails.mapHeight The height of the rendered google map
 * @param {number} mapDetails.mapWidth The width of the rendered google map
 * @param {number} mapDetails.zoom The zoom level of the google map
 */
export const getStaticGoogleMapBounds = ({
  centerLocationCoordinate,
  mapHeight,
  mapWidth,
  zoom,
}) => {
  const extentTopLeft = pointToLonLat({
    centerLocationCoordinate,
    mapHeight,
    mapWidth,
    pixelCoordinate: [0, 0],
    zoom,
  });

  const extentBottomRight = pointToLonLat({
    centerLocationCoordinate,
    mapHeight,
    mapWidth,
    pixelCoordinate: [mapWidth, mapHeight],
    zoom,
  });

  return [extentTopLeft, extentBottomRight];
};

/**
 * Call the google static maps api
 * @param {Object} apiParams
 * @param {[number, number]} apiParams.centerLocationCoordinate The center of the map as a WGS84 lon,lat
 * @param {boolean} apiParams.centerMarker Whether to add a center marker to the map
 * @param {number} apiParams.mapHeight The requested map height
 * @param {number} apiParams.mapWidth The requested map width
 * @param {number} apiParams.zoom The zoom level of the google map
 */
export const getGoogleStaticMapUrl = ({
  centerLocationCoordinate,
  centerMarker,
  mapHeight,
  mapWidth,
  zoom,
}) => {
  const latLonStr = [...centerLocationCoordinate].reverse().join(',');
  const markerParam = centerMarker
    ? `&markers=color:${SAGE.replace('#', '0x')}%7C${latLonStr}`
    : '';
  const googleMapsToken = window.processEnv?.BuyersAwareReactAppGoogleMapsToken;
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latLonStr}&zoom=${zoom}&scale=2&size=${mapWidth}x${mapHeight}${markerParam}&style=feature:poi%7Cvisibility:off&key=${googleMapsToken}`;
};
