import { Link } from 'react-router-dom';
import idrtLogo from '../assets/idrt-logo.svg';
import tdemLogo from '../assets/tdem-logo.svg';
import texasAandMLogo from '../assets/texas-a-and-m-logo.svg';

export const Footer = () => {
  const columnList = 'flex flex-col my-1 [&>ul]:text-lg [&>ul]:text-snow [&>ul]:my-2.5';
  const columnTitle = 'font-condensed font-lg text-[rgba(255,255,255,0.8)]';

  const logos = (
    <div className="my-3 flex w-full shrink-0 flex-row flex-wrap gap-4 md:ml-3 md:flex-col">
      <a href="https://idrt.tamug.edu/">
        <img src={idrtLogo} alt="IDRT Logo" className="h-[56px]" />
      </a>
      <a href="https://www.tdem.texas.gov/">
        <img src={tdemLogo} alt="TDEM Logo" className="h-[48px] md:ml-1.5" />
      </a>
      <a href="https://www.tamu.edu/">
        <img src={texasAandMLogo} alt="Texas A&M Logo" className="h-[44px] md:ml-2" />
      </a>
    </div>
  );

  return (
    <div className="bg-rain">
      <footer className="bottom-0 mx-auto flex w-full max-w-[1200px] grow flex-wrap justify-start gap-3 pl-2 pr-6 sm:justify-between md:flex-nowrap">
        <div className="hidden md:block">{logos}</div>
        <div className={columnList}>
          <ul>
            <li className={columnTitle}>About</li>
            <li>
              <Link to="/about">About us</Link>
            </li>
            <li>
              <Link to="/methodology">Methodology</Link>
            </li>
            <li>
              <Link to="/terms">Privacy & Terms of Service</Link>
            </li>
          </ul>
        </div>

        <div className={columnList}>
          <ul>
            <li className={columnTitle}>Resources</li>
            <li>
              <Link to="/mitigation">Mitigation</Link>
            </li>
            <li>
              <Link to="https://msc.fema.gov/portal/home">FEMA Flood Maps</Link>
            </li>
          </ul>
        </div>

        <div className={columnList}>
          <ul>
            <li className={columnTitle}>Contact Us</li>
            <li>
              <a href="mailto:tdis@tamu.edu">Tell us what you think</a>
            </li>
            {/* <li>
              <Link to="/">Report an issue</Link>
            </li> */}
          </ul>
        </div>
        <div className="block md:hidden">{logos}</div>
      </footer>
    </div>
  );
};
