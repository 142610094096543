import { format } from 'd3-format';
import {
  HIGH_RISK,
  LOW_RISK,
  MODERATE_HIGH_RISK,
  MODERATE_LOW_RISK,
  MODERATE_RISK,
} from '../constants/colors';

export const statusIsGood = status => status >= 200 && status < 300;
export const statusIsUnauthorized = status => status === 401;

export const getSlugFromName = str => (str ? str.toLowerCase().split(' ').join('_') : null);

export const getLocalStorageItem = key => {
  try {
    const result = localStorage.getItem(key);
    return result;
  } catch {
    return null;
  }
};

export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, value);
  } catch {
    // since we only use this as a backup to in-memory state, don't need additional action on error
  }
};

export const getRandomInt = max => Math.floor(Math.random() * max);

export const makeRandomId = length => {
  var result = '';
  var characters = '0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/**
 * @param {number} number
 * @returns Rounded number formatted to dollars with thousands comma separator
 */
export const formatDollarsRound = number => `$${format(',')(Math.round(number))}`;

/**
 * @param {number} number
 * @returns Rounded number formatted to a percent eg. formatPercentRound(0.5) returns '50%'
 */
export const formatPercentRound = number => format('.0%')(number);

/**
 * @param {number} number
 * @returns Number formatted to two significant figures. format2SigFig(1.2345) returns '1.23'
 */
export const formatDecimal2SigFig = number => format('.2f')(number);

/**
 * @param {number} risk
 * @returns Human-readable risk value
 */
export const riskToText = risk => {
  const riskToTextLookup = {
    1: 'Low',
    2: 'Moderate Low',
    3: 'Moderate',
    4: 'Moderate High',
    5: 'High',
  };
  return riskToTextLookup[risk] || 'Unknown';
};

export const riskToColorLookup = risk => {
  const riskToColorHexLookup = {
    1: LOW_RISK,
    2: MODERATE_LOW_RISK,
    3: MODERATE_RISK,
    4: MODERATE_HIGH_RISK,
    5: HIGH_RISK,
  };
  return riskToColorHexLookup[risk] || '#000000';
};

/**
 * @param {number} risk
 * @returns tailwind css text color class for the risk
 */
export const riskToTextColorClass = risk => {
  const riskToTextColorClassLookup = {
    1: 'text-low-text',
    2: 'text-moderateLow-text',
    3: 'text-moderate-text',
    4: 'text-moderateHigh',
    5: 'text-high',
  };
  return riskToTextColorClassLookup[risk] || 'text-onyx';
};

/**
 * @param {number} risk
 * @returns tailwind css fill class for the risk
 */
export const riskToFillClass = risk => {
  const riskToFillColorClassLookup = {
    1: 'fill-low',
    2: 'fill-moderateLow',
    3: 'fill-moderate',
    4: 'fill-moderateHigh',
    5: 'fill-high',
  };
  return riskToFillColorClassLookup[risk] || 'fill-black';
};

/**
 * Capitalizes the first letter of a string
 * @param {string} string
 */
export const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1);

export const SUPPORTED_COUNTIES = {
  Aransas: {
    center: [-97.3552785, 28.0617229],
  },
  Austin: {
    center: [-96.6429024, 29.8494317],
  },
  Bastrop: {
    center: [-97.6665652, 30.1032742],
  },
  Bee: {
    center: [-98.0623223, 28.4248724],
  },
  Brazoria: {
    center: [-95.7947338, 29.1821725],
  },
  Caldwell: {
    center: [-98.7899506, 29.8470663],
  },
  Calhoun: {
    center: [-97.8089667, 28.3726643],
  },
  Chambers: {
    center: [-95.0154439, 29.6932785],
  },
  Colorado: {
    center: [-96.854472, 29.6048498],
  },
  DeWitt: {
    center: [-97.6953943, 29.0990912],
  },
  Fayette: {
    center: [-97.2735183, 29.8964051],
  },
  'Fort Bend': {
    center: [-96.086249, 29.5257939],
  },
  Galveston: {
    center: [-95.4596476, 29.3300786],
  },
  Goliad: {
    center: [-97.7957096, 28.6572036],
  },
  Gonzales: {
    center: [-99.8622971, 29.428665],
  },
  Grimes: {
    center: [-96.325905, 30.5448324],
  },
  Hardin: {
    center: [-94.7314931, 30.3123257],
  },
  Harris: {
    center: [-96.0940344, 29.8335056],
  },
  Jackson: {
    center: [-96.9535329, 28.9693415],
  },
  Jasper: {
    center: [-94.4925908, 30.7000342],
  },
  Jefferson: {
    center: [-95.3121257, 29.8436428],
  },
  Karnes: {
    center: [-98.2124654, 28.9450868],
  },
  Kleberg: {
    center: [-98.2718766, 27.4222946],
  },
  Lavaca: {
    center: [-97.2299816, 29.3481685],
  },
  Lee: {
    center: [-97.3171741, 30.2947789],
  },
  Liberty: {
    center: [-95.1337157, 30.189096],
  },
  Matagorda: {
    center: [-96.6048486, 28.754323],
  },
  Montgomery: {
    center: [-97.8310488, 30.3100705],
  },
  Newton: {
    center: [-94.3690018, 30.7141512],
  },
  Nueces: {
    center: [-98.1226407, 27.7766594],
  },
  Orange: {
    center: [-95.0854947, 30.0505869],
  },
  Polk: {
    center: [-95.1987023, 30.8179626],
  },
  Refugio: {
    center: [-97.4822488, 28.3083172],
  },
  Sabine: {
    center: [-94.1515969, 31.3732059],
  },
  'San Jacinto': {
    center: [-95.4240869, 30.6132599],
  },
  'San Patricio': {
    center: [-97.8516048, 28.0004962],
  },
  Tyler: {
    center: [-94.6841252, 30.7928414],
  },
  Victoria: {
    center: [-97.3037699, 28.7950805],
  },
  Walker: {
    center: [-95.9249312, 30.7814065],
  },
  Waller: {
    center: [-96.3274187, 29.9869788],
  },
  Wharton: {
    center: [-96.5707817, 29.2987428],
  },
};

// currenty set to 4201 Main St, Houston
export const AREA_MAP_DEFAULT_CENTER = [-95.3848143, 29.7343079];

// bounding box for coverage area
export const COVERAGE_AREA_BOUNDS = {
  north: 31.61184,
  south: 27.20927,
  east: -93.50804,
  west: -98.19099,
};

export * from './mapping';
