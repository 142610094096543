import clsx from 'clsx';
import { geoPath, geoTransform } from 'd3-geo';
import { useMemo } from 'react';

import { FLOOD } from '../constants/general';
import { useGetNeighborhoodProfileQuery } from '../services/api';
import {
  getGoogleStaticMapUrl,
  getStaticGoogleMapBounds,
  lonLatToPoint,
  riskToFillClass,
} from '../utils';
import { SectionLoader } from './Loaders';

const MAP_HEIGHT = 200;
const MAP_WIDTH = 200;
const ZOOM = 16;
const LOGO_PADDING = 26;

const wrapperClass = 'mt-3 h-[200px] w-[200px]';

const NeighborhoodRisk = ({ coordinates, riskType }) => {
  const {
    data: neighborhoodProfile,
    error,
    isLoading,
  } = useGetNeighborhoodProfileQuery({
    bbox: getStaticGoogleMapBounds({
      centerLocationCoordinate: coordinates,
      mapHeight: MAP_HEIGHT,
      mapWidth: MAP_WIDTH,
      zoom: ZOOM,
    }),
  });

  // https://github.com/d3/d3-geo#transforms
  // Generate a path creator function that will properly transform the input coordinates
  // to our projection
  const pathGenerator = useMemo(
    () =>
      geoPath().projection(
        geoTransform({
          point: function (x, y) {
            this.stream.point.apply(
              this.stream,
              lonLatToPoint({
                centerLocationCoordinate: coordinates,
                locationCoordinate: [x, y],
                mapHeight: MAP_HEIGHT,
                mapWidth: MAP_WIDTH,
                zoom: ZOOM,
              })
            );
          },
        })
      ),
    [coordinates]
  );

  const accessor = riskType === FLOOD ? 'flood_score' : 'fire_score';

  const mapUrl = useMemo(() => {
    return getGoogleStaticMapUrl({
      centerLocationCoordinate: coordinates,
      mapHeight: MAP_HEIGHT,
      mapWidth: MAP_WIDTH,
      zoom: ZOOM,
    });
  }, [coordinates]);

  if (error) {
    return (
      <div className={clsx(wrapperClass, 'bg-gray-200, p-2')}>Unable to load area risk map</div>
    );
  }

  return (
    <div className={clsx(wrapperClass, 'relative')}>
      {isLoading ? (
        <SectionLoader dotColor={'slate'} />
      ) : (
        <>
          <img className="absolute w-[full]" src={mapUrl} alt="map centered on location" />
          <svg className="absolute" height={MAP_HEIGHT - LOGO_PADDING} width={MAP_WIDTH}>
            {neighborhoodProfile.features.map(feature => {
              const path = pathGenerator(feature);
              const fillClass = riskToFillClass(feature.properties[accessor]);
              return (
                <path
                  className={clsx(fillClass, 'stroke-white')}
                  d={path}
                  key={feature.properties.id}
                  opacity={0.5}
                ></path>
              );
            })}
          </svg>
        </>
      )}
    </div>
  );
};

export default NeighborhoodRisk;
