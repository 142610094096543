// Helper function to log GA events
export const trackEvent = (category, dimensions) => {
  if (!window.gtag) {
    return;
  }
  window.gtag('event', category, dimensions);
};

/* Variables to keep track of analytics categories and common events so they're kept consistent */

// CATEGORIES
export const NAVIGATION = 'navigation';
export const ADDRESS_LOOKUP = 'address_lookup';
export const GENERATE_REPORT = 'generate_report';
export const MAP_PARCEL_CLICK = 'map_parcel_click';
