import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { FIRE, FLOOD } from '../constants/general';
import { useGetRiskForIdQuery, useGetRiskForAddressQuery } from '../services/api';
import { capitalize } from '../utils';
import { Button } from './general/Button';
import { DownloadReportLink } from './general/DownloadReportLink';
import RiskIcon from './general/RiskIcon';
import { SectionLoader } from './Loaders';
import ResultsStreetview from './ResultsStreetview';
import FemaZoneWarning from './FemaZoneWarning';
import ResultsAssessment from './ResultsAssessment';
import { Fragment } from 'react';
import { NoDataForAddress } from './general/NoDataForAddress';
import FFElevation from './FFElevation';

const Break = () => <div className="h-[1px] bg-fossil" />;

const FullReportSection = ({ address, coordinates }) => {
  const navigate = useNavigate();
  return (
    <div className="mb-4 flex flex-col items-center justify-center gap-4 ">
      <Button
        className="mt-0 !px-4 !py-2 sm:mx-8"
        onClick={() => {
          const encodedAddress = encodeURIComponent(address);
          navigate(`/property/overview?address=${encodedAddress}&lonlat=${coordinates}`);
        }}
      >
        View full risk profile for this address
      </Button>
      <DownloadReportLink address={address} coordinates={coordinates}>
        Download risk report
      </DownloadReportLink>
    </div>
  );
};

const PropertyRiskPreviewContent = ({
  isLoading,
  error,
  overlayClass,
  riskData,
  setActiveProperty,
}) => {
  const wrapperClass =
    'absolute right-0 z-20 h-full w-full max-w-full overflow-y-scroll border-l-rain bg-white p-4 shadow';
  return (
    <div className={clsx(wrapperClass, overlayClass)}>
      <div className="mb-6 flex items-center justify-between">
        <h2 className="font-condensed text-lg font-bold text-rain md:text-xl">
          {isLoading || error ? '' : riskData.address}
        </h2>
        <button aria-label="Close" className="self-start" onClick={() => setActiveProperty(null)}>
          <XMarkIcon className="h-6 w-6 text-gray" />
        </button>
      </div>
      {isLoading ? (
        <SectionLoader dotColor="slate" />
      ) : error ? (
        <div className="flex flex-col gap-6 text-center">
          <NoDataForAddress />
        </div>
      ) : (
        <div>
          <div className="mb-6 flex justify-center">
            <ResultsStreetview coordinates={riskData.coordinates} includeMap={false} />
          </div>
          <div className="-mb-4 mt-6 flex justify-center gap-[50px]">
            <div className="w-[120px]">
              <RiskIcon risk={riskData[FLOOD].risk} riskType={FLOOD} />
            </div>
            <div className="w-[120px]">
              <RiskIcon risk={riskData[FIRE].risk} riskType={FIRE} />
            </div>
          </div>
          <FullReportSection address={riskData.address} coordinates={riskData.coordinates} />
          <Break />
          {[FLOOD, FIRE].map(risk => {
            const data = riskData[risk];
            return (
              <Fragment key={risk}>
                <div className="my-4">
                  <h3 className="mb-4 font-condensed text-lg font-bold text-rain md:text-xl">
                    {capitalize(risk)} Risk
                  </h3>
                  <div className="flex items-center justify-between gap-8 px-4">
                    <div className="w-1/2">
                      <RiskIcon risk={data.risk} riskType={risk} />
                    </div>
                    {risk === FLOOD && (
                      <div className="w-1/2">
                        <FemaZoneWarning isFemaSpecialHazardArea={data.isFemaSpecialHazardArea} />
                      </div>
                    )}
                  </div>
                  <ResultsAssessment
                    address={riskData.address}
                    coordinates={riskData.coordinates}
                    data={data}
                    riskType={risk}
                    preview={true}
                  />
                </div>
                <Break />
              </Fragment>
            );
          })}
          <div className="mb-6">
            <FullReportSection address={riskData.address} coordinates={riskData.coordinates} />
          </div>
          <Break />
          <div className="mb-6">
            <FFElevation />
          </div>
        </div>
      )}
    </div>
  );
};

const PropertyRiskById = props => {
  const { activeProperty } = props;
  const {
    data: riskData,
    error,
    isLoading,
  } = useGetRiskForIdQuery({
    id: activeProperty.id,
  });

  return (
    <PropertyRiskPreviewContent
      {...props}
      error={error}
      isLoading={isLoading}
      riskData={riskData}
    />
  );
};

const PropertyRiskByAddress = props => {
  const { activeProperty } = props;
  const {
    data: riskData,
    error,
    isLoading,
  } = useGetRiskForAddressQuery({
    address: activeProperty.address,
    coordinates: activeProperty.coordinates,
  });

  return (
    <PropertyRiskPreviewContent
      {...props}
      error={error}
      isLoading={isLoading}
      riskData={riskData}
    />
  );
};

export const PropertyRiskPreview = props => {
  const { activeProperty } = props;
  if (activeProperty.id) {
    return <PropertyRiskById {...props} />;
  }
  return <PropertyRiskByAddress {...props} />;
};
