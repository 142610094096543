export const PhotoSectionHeader = ({ content, header, imgSrc }) => {
  return (
    <div className="flex flex-wrap items-center bg-snow sm:flex-nowrap">
      <div className="flex w-full shrink-0 items-center md:justify-center p-6 sm:w-1/2 sm:p-4 lg:m-8">
        <div className="sm:w-4/5 lg:w-2/3">
          <h1 className="font-condensed text-3xl text-rain lg:text-4xl xl:text-5xl">{header}</h1>
          <p className="text-rain">{content}</p>
        </div>
      </div>
      <div className="grow-1 shrink-1 flex w-full justify-center py-3 sm:w-1/2 sm:py-0">
        <img className="max-h-[240px] max-w-full sm:max-h-full" src={imgSrc} alt="" />
      </div>
    </div>
  );
};
