import React, { useEffect, useState } from 'react';
import { FIRE, FLOOD } from '../constants/general';
import { DownloadReportLink } from './general/DownloadReportLink';

const RiskText = ({ riskType, risk, isFemaSpecialHazardArea }) => {
  const femaWarning = (
    <p>
      Note that this property falls within a FEMA designated special flood hazard area (sometimes
      referred to as the “100-year floodplain”). This designation{' '}
      <strong>
        requires property owners with a federally-backed mortgage to purchase flood insurance
      </strong>
      . See our Methodology page for more information about SFHAs.
    </p>
  );
  switch (riskType) {
    case FIRE:
      switch (risk) {
        case 1:
          return (
            <p>
              Based on the wildfire risk variables outlined below, our analysis ranks this site as
              having a <strong>low wildfire risk</strong>. Exploring mitigation and preparedness
              strategies may be useful for understanding and avoiding the factors that exacerbate
              wildfire risk.
            </p>
          );
        case 2:
          return (
            <p>
              Based on the wildfire risk variables outlined below, our analysis ranks this site as
              having a <strong>low-moderate wildfire risk</strong>. Exploring mitigation and
              preparedness strategies may be useful for understanding and avoiding the factors that
              exacerbate wildfire risk.
            </p>
          );
        case 3:
          return (
            <p>
              Based on the wildfire risk variables outlined below, our analysis ranks this site as
              having a <strong>moderate wildfire risk</strong>. We encourage you to explore
              mitigation and preparedness strategies to understand and reduce your wildfire risk.
            </p>
          );
        case 4:
          return (
            <p>
              Based on the wildfire risk variables outlined below, our analysis ranks this site as
              having a <strong>moderate-high wildfire risk</strong>. We encourage you to explore
              mitigation and preparedness strategies to understand and reduce your wildfire risk.
            </p>
          );
        case 5:
          return (
            <p>
              Based on the wildfire risk variables outlined below, our analysis ranks this site as
              having a <strong>high wildfire risk</strong>. This is the highest risk category
              included in our model. We strongly encourage you to explore mitigation and
              preparedness strategies to understand and reduce your wildfire risk.
            </p>
          );
        default:
          return '';
      }
    case FLOOD:
      switch (isFemaSpecialHazardArea) {
        case true:
          switch (risk) {
            case 1:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>low flood risk</strong> and indicates it is within the FEMA
                    regulatory floodplain. While it is considered low risk, we still encourage you
                    to explore the surrounding areas and make risk-informed decisions regarding your
                    potential exposure.
                  </p>
                  {femaWarning}
                </>
              );
            case 2:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>moderate-low flood risk</strong>. While it is considered
                    lower risk, we still encourage you to evaluate the costs of flood insurance and
                    explore the surrounding areas and make risk-informed decisions regarding your
                    potential exposure.
                  </p>
                  {femaWarning}
                </>
              );
            case 3:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>moderate flood risk</strong> and indicates it is within the
                    FEMA regulatory floodplain. As a moderate risk property, we encourage you to
                    purchase flood insurance and explore other actions to mitigate your flood risk.
                  </p>
                  {femaWarning}
                </>
              );
            case 4:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>moderate-high flood risk</strong>. This is near the top of
                    the highest risk categories included in our model. We strongly encourage you to
                    purchase flood insurance and explore other actions to mitigate your flood risk.
                  </p>
                  {femaWarning}
                </>
              );
            case 5:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>high flood risk</strong> and indicates it is within the FEMA
                    regulatory floodplain. These risks could be due to large amounts of historic
                    flood risk nearby, low-lying or poorly drained areas, upstream accumulations of
                    water, or close proximity to surface water sources. As a high-risk property, we
                    strongly encourage you to purchase flood insurance and explore other actions to
                    mitigate your flood risk.
                  </p>
                  {femaWarning}
                </>
              );
            default:
              return '';
          }
        case false:
          switch (risk) {
            case 1:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>low flood risk</strong>. While it is considered low risk, we
                    still encourage you to explore the surrounding areas and make risk-informed
                    decisions regarding your potential exposure.
                  </p>
                  <p>
                    It is also important to note that this property is not in a FEMA designated
                    special flood hazard area (sometimes referred to as the “100-year floodplain”).
                    Owners of properties located outside of these areas are not required to purchase
                    flood insurance, but it is still recommended. Since the 1990s, over 50% of flood
                    loss in Texas has occurred in areas outside of SFHAs.
                  </p>
                </>
              );
            case 2:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>moderate-low flood risk</strong>. While it is considered
                    lower risk, we still encourage you to evaluate the costs of flood insurance and
                    explore the surrounding areas and make risk-informed decisions regarding your
                    potential exposure.
                  </p>
                  <p>
                    It is also important to note that this property is not in a FEMA designated
                    special flood hazard area (sometimes referred to as the “100-year floodplain”).
                    Owners of properties located outside of these areas are not required to purchase
                    flood insurance, but it is still recommended. Since the 1990s, over 50% of flood
                    loss in Texas has occurred in areas outside of SFHAs.
                  </p>
                </>
              );
            case 3:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>moderate flood risk</strong>. We encourage you to purchase
                    flood insurance and explore other actions to mitigate your flood risk.
                  </p>
                  <p>
                    While this property may not be in the FEMA regulatory floodplain (sometimes
                    referred to as the “100-year floodplain”) our analysis indicates that flood
                    risks may be higher than what is currently measured by the regulatory maps.
                    These risks could be due to low-lying or poorly drained areas, or upstream
                    accumulations of water. Owners of properties located outside of FEMA Special
                    Flood Hazard Areas are not required to purchase flood insurance, but we still
                    recommend it. Since the 1990s, over 50% of flood loss in Texas has occurred in
                    areas outside of SFHAs.
                  </p>
                  <p>
                    Buyers Aware was created to help residents understand their exposure to flood
                    risk, including those outside of a FEMA SFHA. For more information about why
                    this property is considered moderate risk when it is not in a FEMA’s SFHA, see
                    our Methodology page.
                  </p>
                </>
              );
            case 4:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>moderate-high flood risk</strong>. This is near the top of
                    the highest risk categories included in our model. We strongly encourage you to
                    purchase flood insurance and explore other actions to mitigate your flood risk.
                  </p>
                  <p>
                    While this property may not be in the FEMA regulatory floodplain (sometimes
                    referred to as the “100-year floodplain”) our analysis indicates that flood
                    risks may be higher than what is currently measured by the regulatory maps.
                    These risks could be due to low-lying or poorly drained areas, or upstream
                    accumulations of water. Owners of properties located outside of FEMA Special
                    Flood Hazard Areas are not required to purchase flood insurance, but we still
                    recommend it. Since the 1990s, over 50% of flood loss in Texas has occurred in
                    areas outside of SFHAs.
                  </p>
                  <p>
                    Buyers Aware was created to help residents understand their exposure to flood
                    risk, including those outside of a FEMA SFHA. For more information about why
                    this property is considered moderate risk when it is not in a FEMA’s SFHA, see
                    our Methodology page.
                  </p>
                </>
              );
            case 5:
              return (
                <>
                  <p>
                    Based on the flood risk variables outlined below, our analysis ranks this site
                    as having a <strong>high flood risk</strong>. This is the highest risk category
                    included in our model. We strongly encourage you to purchase flood insurance and
                    explore other actions to mitigate your flood risk.
                  </p>
                  <p>
                    While this property is not in the FEMA regulatory floodplain (sometimes referred
                    to as the “100-year floodplain”), our analysis indicates flood risks may be much
                    higher than what is currently measured by the regulatory maps. These risks could
                    be due to large amounts of historic flood risk nearby, low-lying or poorly
                    drained areas, upstream accumulations of water, or close proximity to surface
                    water sources. Owners of properties located outside of FEMA Special Flood Hazard
                    Areas are not required to purchase flood insurance, but we still recommend it.
                    Since the 1990s, over 50% of flood loss in Texas has occurred in areas outside
                    of SFHAs.
                  </p>
                  <p>
                    Buyers Aware was created to help residents understand their exposure to flood
                    risk, including those outside of a FEMA SFHA. For more information about why
                    this property is considered high risk when it is not in a FEMA’s SFHA, see our
                    Methodology page.
                  </p>
                </>
              );
            default:
              return '';
          }
        default:
          return '';
      }
    default:
      return null;
  }
};

const ResultsAssessment = ({
  address,
  coordinates,
  data,
  riskType,
  preview = false,
  reportPage = false,
}) => {
  // Assume secrets are available globally
  const secrets = window.processEnv;
  console.log('Global secrets IN Result Assesment:', JSON.stringify(secrets));

  // Verify secrets exist before rendering
  if (!secrets) {
    console.error('Global secrets object is not available.');
    return <div>Loading...</div>;
  }

  return (
    <>
      <RiskText
        riskType={riskType}
        risk={data.risk}
        isFemaSpecialHazardArea={data.isFemaSpecialHazardArea}
      />
      {!reportPage && !preview && (
        <DownloadReportLink address={address} coordinates={coordinates}>
          Download Full Report
        </DownloadReportLink>
      )}
      {reportPage && (
        <p>{`For more information about flood zones visit ${secrets.BuyersAwareReactAppSiteURL}methodology`}</p>
      )}
    </>
  );
};

export default ResultsAssessment;
