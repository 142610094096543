import clsx from 'clsx';

import { FemaFloodZoneTooltip, FemaSpecialFloodAreaTooltip } from './general/InfoTooltips';

const FemaZoneWarning = ({ isFemaSpecialHazardArea, reportPage = false }) => {
  const floodZoneText = isFemaSpecialHazardArea
    ? 'Special Flood Hazard Area'
    : 'Not in Special Flood Hazard Area';
  const floodZoneTextClass = clsx(
    'px-2 pb-1 pt-2 font-bold',
    isFemaSpecialHazardArea ? 'bg-whoop-200 text-whoop' : 'bg-gray-100 text-rain'
  );

  const insuranceText = isFemaSpecialHazardArea ? 'Insurance Required' : 'Insurance Not Required';
  const insuranceTextClass = clsx(
    'px-2 pb-2 text-xs italic',
    isFemaSpecialHazardArea ? 'bg-whoop-200 font-bold' : 'bg-gray-100 font-light'
  );

  const tooltipClass = 'translate-x-0.5 translate-y-0.5 shrink-0 self-start';

  return (
    <div className="border border-fossil text-center text-sm">
      <div className="flex items-center justify-between border-b border-fossil bg-snow px-2 py-1 font-condensed text-base">
        {/* Empty div allows centering of text and right aligning of info */}
        {!reportPage && <div className="w-[16px] shrink-0" />}
        <div className="flex-1">FEMA Flood Zone</div>
        {!reportPage && <FemaFloodZoneTooltip className={tooltipClass} />}
      </div>
      <div className={floodZoneTextClass}>
        <div className="flex items-center justify-between gap-2">
          {/* Empty div allows centering of text and right aligning of info */}
          {!reportPage && <div className="w-[16px] shrink-0" />}
          <div className="flex-1">{floodZoneText}</div>
          {!reportPage && <FemaSpecialFloodAreaTooltip className={tooltipClass} />}
        </div>
      </div>
      <div className={insuranceTextClass}>{insuranceText}</div>
    </div>
  );
};

export default FemaZoneWarning;
