import '../style/loaders.css';

const LoadingDots = ({ color }) => {
  const colorToClass = {
    slate: 'after:!bg-slate',
    rain: 'after:!bg-rain',
  };
  const className = colorToClass[color] || '';
  return (
    <>
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
    </>
  );
};

// Full-page loader, with circulating dots
export const PageLoader = ({ dotColor = null }) => (
  <div className="fixed z-[100] flex h-full w-full items-center justify-center">
    <div className="lds-roller">
      <LoadingDots color={dotColor} />
    </div>
  </div>
);

// Section loader, with circulating dots
export const SectionLoader = ({ dotColor = null }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="lds-roller">
        <LoadingDots color={dotColor} />
      </div>
    </div>
  );
};

// Button loader, with animated dots in a line
export const ButtonLoader = ({ color }) => {
  const colorToClass = {
    slate: '!bg-slate',
    rain: '!bg-rain',
  };
  const className = colorToClass[color] || '';
  return (
    <div className="lds-ellipsis" data-testid="button-loader">
      <div className={className} />
      <div className={className} />
      <div className={className} />
      <div className={className} />
    </div>
  );
};
