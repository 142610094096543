import clsx from 'clsx';

const getButtonStyles = ({ className, disabled, isLoading }) =>
  clsx(
    'mt-8 cursor-pointer bg-sage-600 px-16 py-4 font-condensed text-lg font-bold text-snow drop-shadow-md hover:bg-sage-700',
    (disabled || isLoading) && 'pointer-events-none cursor-auto opacity-50',
    className
  );

/**
 * Simple button component, pass tailwind class properties as className to override default styles
 * @param {{
 *   className?: string,
 *   disabled?: boolean,
 *   isLoading?:boolean,
 * }} props
 */
export const Button = (props = { disabled: false, isLoading: false }) => {
  const { className, ...restOfProps } = props;
  return <button className={getButtonStyles(props)} type="button" {...restOfProps} />;
};

/**
 * Button that is styled to look like a link, pass tailwind class properties as className to override default styles
 * @param {*} props
 */
export const LinkLikeButton = (props = {}) => {
  const { className, ...restOfProps } = props;
  return (
    <button
      className={clsx(
        'bg-transparent m-0 cursor-pointer border-0 p-0 font-condensed text-base font-bold text-rain underline',
        className
      )}
      {...restOfProps}
    />
  );
};
