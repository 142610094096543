import coverageAreaImage from '../../assets/harvey_area_inset.png';
import { SUPPORTED_COUNTIES } from '../../utils';

export const NoDataForAddress = () => {
  return (
    <>
      <div className="font-condensed text-2xl font-bold text-onyx sm:text-3xl">
        Oops...We can't find any details for this property.
      </div>
      <div>
        Please note: Buyers Aware currently only supports Texas addresses in the following counties:{' '}
        <span className="font-bold text-rain">{Object.keys(SUPPORTED_COUNTIES).join(', ')}</span>.
      </div>
      <div className="my-2 max-w-md rounded-sm border border-slate bg-white pt-2 text-center">
        <div className="uppercase text-rain">Coverage Area</div>
        <img
          src={coverageAreaImage}
          alt="Hurricane Harvey affected counties"
          className={'w-full'}
        />
      </div>
    </>
  );
};
