import { SearchSection } from './SearchSection';
import { HowItWorks } from './HowItWorks';
import { CallToActions } from './CallToActions';

export const Landing = () => {
  return (
    <>
      <SearchSection />
      <HowItWorks />
      <CallToActions />
    </>
  );
};
