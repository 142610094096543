import clsx from 'clsx';
import { Link } from 'react-router-dom';

import sandbagsImage from '../assets/sandbags.jpeg';
import propertyFloodImage from '../assets/property-flood.jpg';
import parcelMapImage from '../assets/parcel-map.png';
import coverageAreaImage from '../assets/harvey_area_inset.png';
import { SUPPORTED_COUNTIES } from '../utils';

const centerContent = 'flex justify-center my-4';
const tileWrapper = 'flex max-w-[90%] md:max-w-[1000px]';
const tile = 'flex flex-col justify-between items-center gap-5 md:flex-row md:gap-8 sm:p-4';
const reverseTile = tile + ' flex-col-reverse';
const imageStyle = 'w-full md:w-[420px] h-[250px] md:h-[300px] object-cover';
const textStyle = '[&>h2]:text-xl md:[&>h2]:text-2xl [&>p]:mb-5';

export const CallToActions = () => {
  return (
    <div className="mx-4 my-6 text-rain sm:mx-6 sm:my-8 lg:mx-0">
      <div className={centerContent}>
        <div className={tileWrapper}>
          <div className={tile}>
            <div className={textStyle}>
              <h2>A well-prepared Texas</h2>
              <p className="text-onyx">
                As Texans, our work is directly informed by the devastating experience of Hurricane
                Harvey in August 2017. Our vision is a Texas that is well-prepared for disasters and
                more resilient when they do occur, as a direct result of decision-making tools,
                data, and research that IDRT and our partners provide. Our priority is to ensure our
                research and tools directly respond to the needs of Texas residents and we listen
                directly to our users throughout the process of designing and building tools for the
                public.
              </p>
              <Link to="/about" className="underline">
                LEARN MORE ABOUT US
              </Link>
            </div>
            <img
              src={propertyFloodImage}
              alt="Heat map of downtown Houston showing flood risk"
              className={imageStyle}
            />
          </div>
        </div>
      </div>

      <div className={centerContent}>
        <div className={tileWrapper}>
          <div className={reverseTile}>
            <img
              src={parcelMapImage}
              alt="A flooded neighborhood"
              className={clsx(imageStyle, 'object-left-bottom')}
            />
            <div className={textStyle}>
              <h2>Calculating risk at property level</h2>
              <p className="text-onyx">
                Our property-specific analysis includes data from federal regulatory maps as well as
                advanced statistical models in an easily interpretable and transparent format.
              </p>
              <Link to="/methodology" className="underline">
                VIEW OUR METHODOLOGY
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className={centerContent}>
        <div className={tileWrapper}>
          <div className={tile}>
            <div className={textStyle}>
              <h2>Reducing risk</h2>
              <p className="text-onyx">
                Floods and wildfires are damaging and costly events for residents – in fact,
                Hurricane Harvey ties for the costliest natural disaster in Texas’ history. There
                are, however, several steps that you can take to reduce your risk; these steps are
                referred as mitigation.
              </p>
              <Link to="/mitigation" className="underline">
                EXPLORE MITIGATION STRATEGIES
              </Link>
            </div>
            <img src={sandbagsImage} alt="A house with sandbags" className={imageStyle} />
          </div>
        </div>
      </div>

      <div className={centerContent}>
        <div className={tileWrapper}>
          <div className={reverseTile}>
            <img
              src={coverageAreaImage}
              alt="Hurricane Harvey affected counties"
              className={clsx(imageStyle, 'object-left-bottom')}
            />
            <div className={textStyle}>
              <h2>Coverage Area</h2>
              <p className="text-onyx">
                Buyers Aware currently supports Texas addresses in the following counties:{' '}
                {Object.keys(SUPPORTED_COUNTIES).join(', ')}
              </p>
              <Link to="/map" className="underline">
                VIEW OUR RISK MAP
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
