import clsx from 'clsx';

import infoIcon from '../../assets/icons/info.svg';

const iconWrapperClass = clsx(
  'flex h-[16px] w-[16px] cursor-pointer justify-center rounded-full border border-gray p-[2px]'
);

const InfoTooltip = ({ className = '', content = '' }) => {
  return (
    <div className={className}>
      <button
        aria-describedby="app-tooltip"
        className={iconWrapperClass}
        data-tooltip-id="app-tooltip"
        data-tooltip-html={content}
        data-tooltip-place="right"
      >
        <img className="h-full w-full" alt="info-icon" src={infoIcon} />
      </button>
    </div>
  );
};

const tooltipContentClass = clsx('w-64 text-sm');

const femaFloodZoneTooltipContent = `
  <div class="${tooltipContentClass}"><strong>FEMA flood zones</strong> are areas of land that have been mapped by the Federal Emergency Management Agency (FEMA) based on their level of flood risk. FEMA flood zones are designated by letters that correspond to different levels of flood risk. Visit our Methodology page for more information about flood zones.</div>`;
export const FemaFloodZoneTooltip = ({ className = '' }) => {
  return <InfoTooltip className={className} content={femaFloodZoneTooltipContent} />;
};

const specialFloodAreaTooltipContent = `<div class="${tooltipContentClass}">A <strong>Special Flood Hazard Area (SFHA)</strong> is an area FEMA designates as having a 1% chance of flooding in any given year (also referred to as the base flood or 100-year flood). Importantly for residents, if you live in an SFHA and have a federally backed mortgage, you are required to purchase flood insurance.  Visit our Methodology page for more information about SFHAs.</div>`;
export const FemaSpecialFloodAreaTooltip = ({ className = '' }) => {
  return <InfoTooltip className={className} content={specialFloodAreaTooltipContent} />;
};
