export const Terms = () => {
  const h2Class = 'mt-4 mb-0 font-condensed text-rain';
  return (
    <>
      <div className="flex justify-center bg-snow px-4 py-3 md:px-8 md:py-6 lg:px-12 lg:py-9 [&_p]:mt-1">
        <div className="w-full max-w-screen-lg">
          <h1 className="font-condensed text-3xl text-rain lg:text-4xl xl:text-5xl">
            Terms of Service
          </h1>
          <p className="mb-6 text-sm italic">Last Updated: April 4, 2024</p>
          <p>
            Welcome to Buyers Aware. By accessing or using our website, mobile application, or
            services (collectively, the "Services"), you agree to be bound by these Terms of Service
            ("Terms") and our Privacy Policy. Please read these Terms carefully before using our
            Services.
          </p>
          <h2 className={h2Class}>Acceptance of Terms</h2>
          <p>
            By using our Services, you confirm that you have read, understood, and agreed to these
            Terms. If you do not agree with any part of these Terms, you must not use our Services.
          </p>
          <h2 className={h2Class}>Service Description</h2>
          <p>
            Buyers Aware provides users with information and tools to assess flood and wildfire
            risks in various locations. Our Services are intended for informational purposes only
            and should not be considered as professional or emergency advice.
          </p>
          <h2 className={h2Class}>User Responsibilities</h2>
          <p>
            Legal Use: You agree to use our Services only for lawful purposes and in a way that does
            not infringe the rights of, restrict, or inhibit anyone else's use and enjoyment of the
            Services.
          </p>
          <p>
            Accuracy of Information: You are responsible for ensuring the accuracy of any personal
            information or other data you provide on or through our Services.
          </p>
          <h2 className={h2Class}>Disclaimers</h2>
          <p>
            Information Accuracy: While we strive to provide accurate and up-to-date information, we
            do not warrant that the content on our Services is accurate, complete, reliable,
            current, or error-free.
          </p>
          <p>
            No Emergency Services: Our Services do not provide emergency rescue or support services.
            In the case of an emergency, contact your local emergency services immediately.
          </p>
          <h2 className={h2Class}>Limitation of Liability</h2>
          <p>
            Buyers Aware, its affiliates, and its service providers will not be liable for any
            direct, indirect, incidental, special, consequential, or other damages based on any use
            of this website or any other website to which this site is linked, including, without
            limitation, any lost profits, business interruption, or loss of programs or information,
            even if Texas A&M University or its clients and partners have been specifically advised
            of the possibility of such damages.
          </p>
          <h2 className={h2Class}>Changes to the Terms</h2>
          <p>
            We reserve the right to make changes to these Terms at any time. Your continued use of
            the Services following the posting of changes will mean you accept those changes.
          </p>
        </div>
      </div>
    </>
  );
};
