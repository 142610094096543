import { Link, useSearchParams } from 'react-router-dom';

import { useGetRiskForAddressQuery } from '../services/api';
import { SectionLoader } from './Loaders';
import ResultsOverview from './ResultsOverview';
import TabbedResults from './TabbedResults';
import { AddressSearchBar } from './general/AddressSearchBar';
import { NoDataForAddress } from './general/NoDataForAddress';

export const Results = () => {
  const [searchParams] = useSearchParams();
  const address = searchParams.get('address');
  const lonlat = searchParams.get('lonlat') || '';
  const coordinates = lonlat.split(',').map(v => +v);

  const {
    data: addressRiskData,
    error,
    isLoading,
  } = useGetRiskForAddressQuery({
    address,
    coordinates,
  });

  if (isLoading) {
    return (
      <div className="flex min-h-[calc(75vh-60px)]  flex-col items-center justify-center bg-ice">
        <SectionLoader dotColor="slate" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="relative flex flex-col items-center justify-start bg-hero-image-bw bg-cover bg-top p-8 sm:p-16 md:min-h-[calc(100vh-60px-192px)]">
        <div className="absolute top-0 h-full w-full bg-snow opacity-80"></div>
        <div className="relative mb-4 flex flex-col items-center gap-6">
          <NoDataForAddress />
          <div className="flex flex-col gap-2">
            <div>
              Go{' '}
              <Link className="text-rain underline" to="/">
                home
              </Link>{' '}
              or search for another property
            </div>
            <div className="flex w-[300px]">
              <AddressSearchBar placeholder="Enter your address" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ResultsOverview addressRiskData={addressRiskData} />
      <TabbedResults addressRiskData={addressRiskData} />
    </div>
  );
};
