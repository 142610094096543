import { AddressSearchBar } from './general/AddressSearchBar';

export const SearchSection = () => {
  return (
    <div className="relative">
      <div className="absolute h-full w-full bg-hero-image bg-cover bg-top opacity-90 md:bg-center" />
      <div className="absolute h-full w-full bg-snow opacity-40" />
      <div className="relative flex min-h-[40vh] flex-col items-center justify-center">
        <div className="w-4/5 pb-10 pt-4 md:w-1/2">
          <h1
            className="pb-4 font-condensed text-3xl  sm:text-4xl md:text-5xl"
            style={{ lineHeight: 1.2 }}
          >
            Understand your flood and wildfire risk and take action
          </h1>
          <AddressSearchBar />
        </div>
      </div>
    </div>
  );
};
