import clsx from 'clsx';

import { riskToText } from '../utils';
import RiskIcon from './general/RiskIcon';
import ResultsStreetview from './ResultsStreetview';

/**
 *
 * @param {number} worstRisk
 * @returns CSS radial gradient string
 */
const getRadialRiskGradient = worstRisk => {
  if (worstRisk < 4) {
    return `radial-gradient(#ECF0E9, #F0F3F4)`;
  }
  return `radial-gradient(#FCF3F2, #F0F3F4)`;
};

const ResultsOverview = ({ addressRiskData, reportPage }) => {
  const {
    address,
    coordinates,
    flood: { risk: floodRisk },
    wildfire: { risk: fireRisk },
  } = addressRiskData;

  const tileClass = clsx(
    'flex w-1/2 min-w-[180px] flex-col items-center justify-center rounded border border-gray-200 bg-snow px-4 py-6 drop-shadow-md md:w-1/3',
    reportPage ? 'max-w-[232px]' : 'max-w-[280px] '
  );

  const headerClass = clsx(
    'font-condensed font-bold text-rain',
    reportPage ? 'text-3xl' : 'text-xl'
  );

  const worstRisk = Math.max(floodRisk, fireRisk);
  return (
    <div
      className="flex flex-wrap justify-center gap-8 px-4 py-4 md:px-10 md:py-8 lg:flex-nowrap"
      style={{ background: reportPage ? 'none' : getRadialRiskGradient(worstRisk) }}
    >
      <div className="flex w-[630px] flex-col gap-8">
        <h1 className={headerClass}>Your risk results for {address}</h1>
        <div className="flex flex-wrap justify-center gap-4 sm:gap-8 md:flex-nowrap lg:justify-start">
          <div className={tileClass}>
            <RiskIcon risk={floodRisk} riskType="flood" />
          </div>
          <div className={tileClass}>
            <RiskIcon risk={fireRisk} riskType="wildfire" />
          </div>
        </div>
        <div className="text-rain">
          This property has a <strong>{riskToText(floodRisk)} flood risk</strong> and a{' '}
          <strong>{riskToText(fireRisk)} wildfire risk</strong>.{' '}
          {reportPage
            ? 'View below for details.'
            : 'View the tabs below for details and mitigation tactics.'}
        </div>
      </div>
      <div className="hidden w-[2px] bg-gray-200 lg:block"></div>
      <ResultsStreetview coordinates={coordinates} />
    </div>
  );
};

export default ResultsOverview;
