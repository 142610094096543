import { useEffect, useMemo, useRef, useState } from 'react';

import floodIcon from '../assets/icons/flood.svg';
import fireIcon from '../assets/icons/fire.svg';
import { FIRE, FLOOD } from '../constants/general';
import { capitalize, riskToText } from '../utils';
import {
  AreaContext,
  LineBreak,
  MitigationTactics,
  RiskProfile,
  RiskVariables,
} from './ResultsSections';
import { Button } from './general/Button';
import { TabbedSection } from './general/TabbedSection';
import { DownloadReportLink } from './general/DownloadReportLink';
import FFElevation from './FFElevation';

const TabbedResults = ({ addressRiskData }) => {
  const risks = useMemo(
    () => [
      {
        address: addressRiskData.address,
        coordinates: addressRiskData.coordinates,
        data: addressRiskData[FLOOD],
        icon: floodIcon,
        label: 'Flood Risk',
        riskType: FLOOD,
        subLabel: riskToText(addressRiskData[FLOOD].risk),
      },
      {
        address: addressRiskData.address,
        coordinates: addressRiskData.coordinates,
        data: addressRiskData[FIRE],
        icon: fireIcon,
        label: 'Wildfire Risk',
        riskType: FIRE,
        subLabel: riskToText(addressRiskData[FIRE].risk),
      },
    ],
    [addressRiskData]
  );

  const [activeRiskIndex, setActiveRiskIndex] = useState(0);

  useEffect(() => {
    setActiveRiskIndex(0);
  }, [risks]);

  const tabSectionRef = useRef(null);

  const activeRisk = risks[activeRiskIndex];

  return (
    <TabbedSection
      activeTabIndex={activeRiskIndex}
      setActiveTabIndex={setActiveRiskIndex}
      ref={tabSectionRef}
      tabs={risks}
    >
      <>
        <RiskProfile {...activeRisk} />
        <RiskVariables {...activeRisk} />
        <LineBreak />
        <AreaContext {...activeRisk} />
        <LineBreak />
        <MitigationTactics {...activeRisk} />
        <LineBreak />
        <div className="py-8 text-center md:py-16">
          <div>For full details on this property’s {activeRisk.riskType} risk,</div>
          <DownloadReportLink
            address={addressRiskData.address}
            coordinates={addressRiskData.coordinates}
          >
            Download your risk report
          </DownloadReportLink>
          <div className="mx-4 flex justify-center">
            <Button
              onClick={() => {
                const riskToSetActive = activeRisk.riskType === FLOOD ? FIRE : FLOOD;
                setActiveRiskIndex(risks.findIndex(risk => risk.riskType === riskToSetActive));
                window.scrollTo({
                  top: tabSectionRef.current.getBoundingClientRect().top + window.scrollY - 60,
                });
              }}
            >
              View Your {capitalize(activeRisk.riskType === FLOOD ? FIRE : FLOOD)} Risk Profile
            </Button>
          </div>
        </div>
        {activeRisk.riskType === FLOOD && (
          <>
            <LineBreak />
            <div className="mx-6 mb-6">
              <FFElevation />
            </div>
          </>
        )}
      </>
    </TabbedSection>
  );
};

export default TabbedResults;
