import booleanDisjoint from '@turf/boolean-disjoint';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import floodIcon from '../assets/icons/flood.svg';
import fireIcon from '../assets/icons/fire.svg';
import floodIconSlate from '../assets/icons/flood-slate.svg';
import fireIconSlate from '../assets/icons/fire-slate.svg';
import coverageAreaMasked from '../assets/coverage_area_masked.json';
import coverageArea from '../assets/coverage_area.json';

import { SLATE } from '../constants/colors';
import { FIRE, FLOOD } from '../constants/general';
import { useLazyGetNeighborhoodProfileQuery } from '../services/api';
import { capitalize, riskToColorLookup, AREA_MAP_DEFAULT_CENTER } from '../utils';
import { MAP_PARCEL_CLICK, NAVIGATION, trackEvent } from '../utils/googleAnalytics';
import { AddressSearchBar } from './general/AddressSearchBar';
import { PropertyRiskPreview } from './PropertyRiskPreview';

const MIN_ZOOM_FOR_DATA = 16;

// The size of the overlay (PropertyRiskPreview) with the associated tailwind classes and breakpoints
const OVERLAY_CLASS = 'md:w-[450px] sm:w-[350px]';

const COVERAGE_OVERLAY_STYLES = {
  fillColor: SLATE,
  fillOpacity: '0.5',
  strokeColor: SLATE,
  strokeOpacity: '1',
  strokeWeight: '0.5',
};

// Check whether the map bounds overlap with our border geojson
// If there is no overlap we will warn the user
const mapIsInBounds = bounds => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  // It has a weird name, but boolean disjoint is what we want to
  // quickly test for intersections
  const noIntersection = booleanDisjoint(coverageArea, {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [ne.lng(), ne.lat()],
          [sw.lng(), ne.lat()],
          [sw.lng(), sw.lat()],
          [ne.lng(), sw.lat()],
          [ne.lng(), ne.lat()],
        ],
      ],
    },
  });
  return Boolean(!noIntersection);
};

export const AreaMap = () => {
  useEffect(() => {
    trackEvent(NAVIGATION, { route_name: '/map' });
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const { riskType } = useParams();
  const riskTypeParam = riskType || FLOOD;
  const accessor = riskTypeParam === FLOOD ? 'flood_score' : 'fire_score';

  const [activeProperty, setActiveProperty] = useState(null);

  const mapElementRef = useRef(null); // DOM element for the map
  const mapRef = useRef(null); // Reference that holds our google map class
  const propertyMarker = useRef(null); // Reference that holds marker pin instance

  // default to center of Harris County if user navigates directly to map
  const [lng, lat] = location.state?.coordinates || AREA_MAP_DEFAULT_CENTER;

  const [warnZoom, setWarnZoom] = useState(false);
  const [isInBounds, setIsInBounds] = useState(true);
  const [getAreaData, { data: areaData }] = useLazyGetNeighborhoodProfileQuery();

  // We debounce the call to get area to make sure that we don't call the api too often
  const debouncedGetAreaData = useMemo(() => {
    return debounce(
      bbox => {
        getAreaData({ bbox }, true);
      },
      500,
      { leading: false, trailing: true }
    );
  }, [getAreaData]);

  useEffect(() => {
    const loadGoogleMapsAPI = () => {
      return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        const googleMapsToken = window.processEnv?.BuyersAwareReactAppGoogleMapsToken;
        console.log('Global secrets IN Area Map:', googleMapsToken);
        script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsToken}&libraries=places`;
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });
    };
    // Create the map on mount and store the variable in the ref
    async function initMap() {
      const { Map } = await window.google.maps.importLibrary('maps');
      mapRef.current = new Map(mapElementRef.current, {
        center: { lat, lng },
        clickableIcons: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }],
          },
        ],
        zoom: 17,
      });

      mapRef.current.data.addGeoJson(coverageAreaMasked);
      mapRef.current.data.setStyle(() => COVERAGE_OVERLAY_STYLES);

      // When the map goes idle (after a zoom or a pan is finished), refetch the area
      // data based on the new bounding box
      // Also check if the map is outside of bounds for the coverage area so that we can warn
      // when users have panned outside.
      window.google.maps.event.addListener(mapRef.current, 'idle', function () {
        const bounds = mapRef.current.getBounds();
        const isInBounds = mapIsInBounds(bounds);
        setIsInBounds(isInBounds);
        if (mapRef.current.getZoom() < MIN_ZOOM_FOR_DATA) {
          return;
        }
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        debouncedGetAreaData([
          [ne.lng(), ne.lat()],
          [sw.lng(), sw.lat()],
        ]);
      });

      // Remove the data from the data layer immediately on zoom, this makes for better
      // ui when a user zooms out too far
      window.google.maps.event.addListener(mapRef.current, 'zoom_changed', function () {
        if (mapRef.current.getZoom() < MIN_ZOOM_FOR_DATA) {
          setWarnZoom(true);
          mapRef.current.data.forEach(feature => {
            if (feature.getProperty('name') === 'coverage') {
              return;
            }
            mapRef.current.data.remove(feature);
          });
          return;
        }
        setWarnZoom(false);
      });

      mapRef.current.data.addListener('click', function (event) {
        if (event.feature.getProperty('name') === 'coverage') {
          // Don't track clicks on our out-of-bounds geojson
          return;
        }
        trackEvent(MAP_PARCEL_CLICK);
        const id = event.feature.getProperty('id');
        const coordinates = [event.latLng.lng(), event.latLng.lat()];
        setActiveProperty({ coordinates, id });
      });

      // If users are navigating to the map from the results page, we will have
      // coordinates and address for the most recent address, show a marker for the
      // property on the map
      const showCenterPin = location.state?.address && location.state?.coordinates;
      if (showCenterPin) {
        // Once the map is idle (has initialized) we will show the pin
        window.google.maps.event.addListenerOnce(mapRef.current, 'idle', function () {
          // This should be empty, but React runs mount effects twice in dev mode, so
          // make sure is empty...
          if (propertyMarker.current) {
            propertyMarker.current.setMap(null);
            propertyMarker.current = null;
          }
          // Add the property marker
          const center = new window.google.maps.LatLng(lat, lng);
          propertyMarker.current = new window.google.maps.Marker({
            map: mapRef.current,
            position: center,
          });
          // Ensure if the user clicks on the marker, the property is set to active
          window.google.maps.event.addListener(propertyMarker.current, 'click', function () {
            if (location.state.address && location.state.coordinates) {
              setActiveProperty({
                address: location.state.address,
                coordinates: location.state.coordinates,
              });
            }
          });
        });
      }
    }

    if (window.google && window.google.maps) {
      initMap();
    } else {
      loadGoogleMapsAPI()
        .then(initMap)
        .catch(error => {
          console.error('Failed to load Google Maps API:', error);
        });
    }
  }, [debouncedGetAreaData, lat, lng, location.state]);

  // If we have new area data or have switched the risk, redraw the map with the new data
  useEffect(() => {
    if (areaData && mapRef.current) {
      if (mapRef.current.getZoom() < MIN_ZOOM_FOR_DATA) {
        return;
      }
      mapRef.current.data.forEach(feature => {
        if (feature.getProperty('name') === 'coverage') {
          return;
        }
        mapRef.current.data.remove(feature);
      });
      mapRef.current.data.addGeoJson(areaData);
      mapRef.current.data.setStyle(feature => {
        if (feature.getProperty('name') === 'coverage') {
          return COVERAGE_OVERLAY_STYLES;
        }
        return {
          fillColor: riskToColorLookup(feature.getProperty(accessor)),
          fillOpacity: '0.7',
          strokeColor: '#FFFFFF',
          strokeOpacity: '0.5',
        };
      });
    }
  }, [accessor, areaData, warnZoom]);

  const handleAddressClick = useCallback(({ address, coordinates }) => {
    if (!address || !coordinates) {
      return;
    }
    setActiveProperty({ address, coordinates });
  }, []);

  useEffect(() => {
    if (!mapRef.current) {
      return;
    }
    if (propertyMarker.current) {
      // Super confusing api here, but this is how google handles deleting markers
      propertyMarker.current.setMap(null);
      propertyMarker.current = null;
    }
    if (activeProperty) {
      const center = new window.google.maps.LatLng(
        activeProperty.coordinates[1],
        activeProperty.coordinates[0]
      );
      const proj = mapRef.current.getProjection();
      const centerPt = proj.fromLatLngToPoint(center);

      // Moves the selected center to the visible center
      if (window.innerWidth > 640) {
        mapRef.current.panTo(proj.fromPointToLatLng(centerPt));
      }

      propertyMarker.current = new window.google.maps.Marker({
        map: mapRef.current,
        position: center,
      });
    }
  }, [activeProperty]);

  const outsideCoverageWarning = () => (
    <div className="flex flex-col items-start gap-1 sm:items-center">
      <div>This map is currently outside the coverage area.</div>
      <button
        className="text-xs text-slate underline sm:text-sm"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          if (mapRef.current) {
            const center = new window.google.maps.LatLng(
              AREA_MAP_DEFAULT_CENTER[1],
              AREA_MAP_DEFAULT_CENTER[0]
            );
            mapRef.current.panTo(center);
            mapRef.current.setZoom(MIN_ZOOM_FOR_DATA);
          }
        }}
      >
        Jump to coverage area
      </button>
    </div>
  );

  return (
    <div className="relative h-[calc(100vh-60px)]">
      <div
        className={clsx(
          'absolute h-full',
          activeProperty ? 'w-full sm:w-[calc(100%-350px)] md:w-[calc(100%-450px)]' : 'w-full'
        )}
        ref={mapElementRef}
      ></div>
      <div className="absolute p-2 sm:p-4">
        <div
          className={clsx(
            'relative left-0 pb-2 sm:pb-4 lg:absolute lg:left-[50vw] lg:w-[320px]',
            activeProperty
              ? 'w-[200px] md:w-[300px] lg:-translate-x-[calc(140px+50%)]'
              : 'w-[300px] lg:-translate-x-1/2'
          )}
        >
          <AddressSearchBar
            placeholder="Search for an address"
            onAddressClickCb={handleAddressClick}
          />
        </div>
        <div className="relative z-10 w-[185px] rounded-sm bg-white px-4 py-2 drop-shadow">
          <div className="mb-1 mt-2">
            {[
              [FLOOD, floodIcon, floodIconSlate],
              [FIRE, fireIcon, fireIconSlate],
            ].map(([riskType, riskIcon, riskIconSlate]) => (
              <button
                className={'mb-1 flex w-full cursor-pointer items-center gap-2 p-0.5'}
                key={riskType}
                onClick={() => navigate(`/map/${riskType}`, { replace: true })}
              >
                <div
                  className={clsx(
                    'h-8 w-8 content-center rounded-full border p-1',
                    riskType === riskTypeParam ? 'border-slate' : 'border-gray-200'
                  )}
                >
                  <img
                    className="h-full w-full"
                    src={riskType === riskTypeParam ? riskIconSlate : riskIcon}
                    alt={`${riskType} icon`}
                  />
                </div>
                <div
                  className={clsx(
                    'text-center text-sm font-bold',
                    riskType === riskTypeParam ? 'text-rain' : 'text-gray-400'
                  )}
                >
                  {`${capitalize(riskType)} risk`}
                </div>
              </button>
            ))}
          </div>
          {(warnZoom || !isInBounds) && (
            <div className="mb-1 mt-3 block max-w-full text-xs font-bold text-rain sm:hidden">
              {!isInBounds ? outsideCoverageWarning() : `Zoom in to see ${riskTypeParam} risk data`}
            </div>
          )}
        </div>
      </div>
      <div
        className={clsx(
          'absolute top-[calc(50%-90px)] mb-1 mt-3 hidden max-w-[300px] -translate-x-1/2 rounded-sm bg-[rgba(255,255,255,0.95)] p-4 text-base font-bold text-rain transition-[transform,opacity] duration-300 sm:block md:max-w-full',
          warnZoom || !isInBounds ? '-translate-y-1/2 opacity-100' : '-translate-y-3/4 opacity-0',
          !isInBounds ? 'pointer-events-auto' : 'pointer-events-none',
          activeProperty ? 'left-[calc(50%-175px)] md:left-[calc(50%-225px)]' : 'left-1/2'
        )}
      >
        {!isInBounds ? outsideCoverageWarning() : `Zoom in to see ${riskTypeParam} risk data`}
      </div>
      <div className="absolute bottom-[24px] m-2 rounded-sm bg-white px-4 pb-2 pt-1 drop-shadow sm:m-4">
        <div className="mb-2 text-sm">{`${capitalize(riskTypeParam)} Risk`}</div>
        <div className="flex">
          {[[1, 'Low'], [2], [3, 'Medium'], [4], [5, 'High']].map(([risk, text]) => (
            <div className="flex w-8 flex-col sm:w-10" key={risk}>
              <div
                className="h-2 opacity-70 sm:h-3"
                style={{ background: riskToColorLookup(risk) }}
              />
              <div className="pt-1 text-center text-xs">{text}</div>
            </div>
          ))}
        </div>
      </div>
      {activeProperty && (
        <PropertyRiskPreview
          activeProperty={activeProperty}
          overlayClass={OVERLAY_CLASS}
          setActiveProperty={setActiveProperty}
        />
      )}
    </div>
  );
};
