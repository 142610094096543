import { SET_EXAMPLE_MODAL } from '../actions/modals';

const defaultState = {
  showExampleModal: null,
};

const modalsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_EXAMPLE_MODAL:
      return {
        ...state,
        showExampleModal: action.payload,
      };
    default:
      return state;
  }
};

export default modalsReducer;
