import clsx from 'clsx';
import { useState } from 'react';
import { NavLink, matchPath, useLocation } from 'react-router-dom';

import { Bars3Icon } from '@heroicons/react/24/outline';
import logo from '../assets/baw-logo-horizontal-color.svg';
import { AddressSearchBar } from './general/AddressSearchBar';

export const Header = () => {
  const { pathname } = useLocation();
  const shouldShowAddressSearch = pathname !== '/' && !matchPath('/map/*', pathname);
  const [menuOpened, setMenuOpened] = useState(false);
  const [mobileView, setMobileView] = useState(window.innerWidth < 850);

  window.addEventListener('resize', () => {
    window.innerWidth < 850 ? setMobileView(true) : setMobileView(false);
  });

  const navClass = clsx(
    'whitespace-nowrap uppercase text-rain underline-offset-4 text-xl hover:underline'
  );
  const menuNavClass = clsx(`mx-6 my-2 block uppercase text-rain`);

  const headerHeightTw = 'h-[60px]';
  const headerTopTw = 'top-[60px]';

  return (
    <header className="sticky top-0 z-50">
      <div
        className={`z-10 flex w-full items-center justify-between border-b border-gray-300 bg-snow shadow ${headerHeightTw}`}
      >
        <div className="flex w-full items-center justify-between gap-6 px-6">
          <div className={`flex grow items-center gap-6 ${headerHeightTw}`}>
            <NavLink to="/" className="mr-2 flex items-center">
              <img className="mr-2 w-[240px]" src={logo} alt="Buyers Aware logo" />
            </NavLink>
            {shouldShowAddressSearch && (
              <div className="hidden max-w-[300px] grow sm:block">
                <AddressSearchBar placeholder="Enter your address" />
              </div>
            )}
          </div>
          {mobileView ? (
            <>
              <button
                aria-label="Open Menu"
                className="flex cursor-pointer"
                onClick={() => setMenuOpened(!menuOpened)}
              >
                <Bars3Icon className="h-[25px] w-[25px] text-gray" />
              </button>
              {menuOpened && (
                <nav
                  className={`absolute left-0 w-full border-b border-gray bg-snow font-condensed text-xl shadow ${headerTopTw}`}
                >
                  <NavLink className={menuNavClass} to="/map" onClick={() => setMenuOpened(false)}>
                    Map View
                  </NavLink>
                  <NavLink
                    className={menuNavClass}
                    to="/about"
                    onClick={() => setMenuOpened(false)}
                  >
                    About us
                  </NavLink>
                  <NavLink
                    className={menuNavClass}
                    to="/methodology"
                    onClick={() => setMenuOpened(false)}
                  >
                    Methodology
                  </NavLink>
                  <NavLink
                    className={menuNavClass}
                    to="/mitigation"
                    onClick={() => setMenuOpened(false)}
                  >
                    Mitigation
                  </NavLink>
                </nav>
              )}
            </>
          ) : (
            <nav className="flex gap-6 font-condensed text-xl lg:gap-8">
              <NavLink className={navClass} to="/map">
                Map View
              </NavLink>
              <NavLink className={navClass} to="/about">
                About us
              </NavLink>
              <NavLink className={navClass} to="/methodology">
                Methodology
              </NavLink>
              <NavLink className={navClass} to="/mitigation">
                Mitigation
              </NavLink>
            </nav>
          )}
        </div>
      </div>
    </header>
  );
};
